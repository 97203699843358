.smart-img-wrap {
	position: relative;
}
.smart-post-category {
	position: absolute;
	text-transform: uppercase;
	font-weight: bold;
	bottom: 10px;
	left: 10px;
	padding: 5px 10px;
	background: #dc2500;
	color: white;
	&:hover {
		background: darken(#dc2500, 15%);
		color: white;
	}
}
.reportage-container {
	position: relative;

	.smart-post-category {
		@media (min-width: 768px) {
			left: 90px;
			bottom: 27px;
		}
	}
}

.home {
	.hero-unit4.snippet,
	.hero-unit3.snippet {
		.featured-image {
			width: 100%;
		}
		.snippet-title {
			width: 100%;
			display: block;
		}
		@media (min-width: 768px) {
			display: flex;
			justify-content: space-between;
			.featured-image {
				flex: 0 0 32%;
			}
			& > a {
				flex: 0 0 65%;
			}
			.snippet-title {
				width: 100%;
				display: block;
			}
		}
	}
}