.category-grid {
	flex: 1;
	display: grid;
	justify-content: stretch;
	align-items: stretch;
	grid-gap: 1px 1px;
	grid-template-columns: 1fr;
	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr;
	}
	@media (min-width: 1024px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
	.item {
		box-shadow: 0 0 0 1px $dark-grey;
	}
	.link {
		height: 100%;
		display: flex;
		align-items: center;
		padding: 45px 20px;
		background: #f9f9f9;
		&:hover {
			background: darken(#f9f9f9, 15%);
			text-decoration: none;
		}
	}
	.icon {
		height: 30px;
	}
	.label {
		margin-left: 20px;
		font-weight: normal;
		color: $font-color;
		font-size: 16px;
	}
}
.company-grid {
	display: inline-grid;
	margin: 0 -10px;
	grid-template-columns: 1fr;
	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
	.company {
		margin: 0 10px 20px;
		border: 1px solid black;
		background: #f9f9f9;
		transition: background 150ms ease;
		&:hover {
			background: darken(#f9f9f9, 15%);
		}
		.company-inner {
			
		}
		.content {
			padding: 7px;
			margin-top: 7px;
		}
		.title {
			font-weight: normal;
			a {
				color: $font-color;
			}
		}
		.address {
			margin-bottom: 10px;
		}
		.terms {
			a {
				color: $font-color;
				font-weight: bold;
			}
		}
	}
}

.company-single {
	.single-hero {
		margin-bottom: 60px;
		.contact {
			display: flex;
			justify-content: center;
			align-items: center;
			background: $primary-color;
			color: white;
			font-size: 18px;
			padding: 0 5px 12px;
			flex-direction: column;
			@media (min-width: 768px) {
				flex-direction: row;
			}
		}
		.icon {
			padding-bottom: 10px;
			margin: 0 10px;
		}
		a {
			color: white;
		}
		.slick-slide {
			height: auto;
		}
		.image-holder {
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			padding-bottom: 60%;
			height: 0;
		}
	}
	.single-content {
		font-size: 18px;
		margin-bottom: 2rem;
		h2 {
			margin-bottom: 32px;
			@media (min-width: 768px) {
				font-weight: normal;
				font-size: 72px;
			}
		}
		h1,h3,h4,h5,h6 {
			margin-bottom: 1em;
		}
		@media (min-width: 1024px) {
			margin-right: 150px;
		}
	}
	.cats-tags {
		margin-bottom: 2rem;
		font-size: 20px;
		a {
			color: $font-color;
			font-weight: bold;
		}
	}
	.contact-box {
		display: flex;
		flex-direction: column;
		@media (min-width: 768px) {
			flex-direction: row;
		}
		.image {
			flex: 1;
			margin-bottom: 2rem;
			@media (min-width: 768px) {
				margin: 0;
			}
		}
		.content {
			flex: 1;
			padding: 0 1rem;
			@media (min-width: 768px) {
				padding: 0 2rem;
			}
		}
		a {
			color: #0070BB;
		}
		.title {
			margin-bottom: 24px;
			@media (min-width: 768px) {
				font-size: 50px;
				font-weight: normal;
			}
		}
		.company-logo {
			max-width: 180px;
			height: 80px;
			padding: 0;
			margin-bottom: 35px;
			background-size: contain;
		}
		.address {
			margin-bottom: 19px;
		}
		.web {
			margin-bottom: 24px;
			color: #0070BB;
		}
		.acf-map {
			height: 300px;
		}
	}
}
.some {
	display: flex;
	margin-bottom: 2rem;
	flex-direction: column;
	@media (min-width: 768px) {
		align-items: center;
		flex-direction: row;
	}
	.share-strip {
		float: none;
		top: auto;
		position: static;
		margin: 0 10px 1rem 0;
		background: none;

		.share-by-mail {
			background: #d02933;
		}
		@media (min-width: 768px) {
			margin: 0 10px 0 0;
		}
	}
}

.company-page {
	padding: 2rem 0 5rem;
	min-height: 36vh;
	* {
		font-family: 'Abel', sans-serif;
		box-sizing: border-box;
	}
	.header {
		margin-bottom: 22px;
	}
	.logo {
		flex: 1;
		img {
			max-width: 285px;
		}
	}
	.breadcrumbs {
		display: flex;
		align-items: center;
		padding-top: 1rem;
		font-size: 18px;
		margin-bottom: 1rem;
		@media (min-width: 768px) {
			margin-bottom: 0;
		}
		.icon {
			margin: 0 10px;
		}
		a {
			color: $font-color;
		}
	}
	.search {
		width: 100%;
		padding-bottom: 8px;
		margin: 0 auto;
		@media (min-width: 768px) {
			width: 40%;
		}
		.search-form {
			border: 1px solid rgb(104, 104, 104);
			width: auto;
			display: flex;
			align-items: stretch;
		}
		.search-text {
			flex: 1 1 auto;
			padding: 0 10px;
			border: none;
			background: $light-grey;
		}
		.search-submit {
			flex: 0 0 43px;
			height: 43px;
			border-radius: 0;
			border: none;
			background-color: $primary-color;
			background-image: url(images/icon-search.png);
			background-repeat: no-repeat;
			background-position: center -8px;
			background-size: 60%;
		}
	}
	.page-content {
		.title {
			font-weight: normal;
			@media (min-width: 768px) {
				font-size: 3.8rem;
			}
		}
		.image-holder {
			margin-bottom: 30px;
		}
		hr {
			margin-bottom: 30px;
		}
		.columns {
			display: flex;
			flex-direction: column;
			@media (min-width: 768px) {
				flex-direction: row;
			}
		}
		.left {
			flex: 3;
			float: none;
			margin: 0 0 2rem 0;
			@media (min-width: 768px) {
				margin: 0 30px 0 0;
			}
		}
		.right {
			flex: 1;
			float: none;
			background: #DFDFDF;
			padding: 22px 30px;
			margin: 0;
		}
		.gform_wrapper {
			label {
				display: none;
			}
			input[type="text"] {
				border: none;
				border-bottom: 1px solid $font-color;
				background: transparent;
				width: 100%;
			}
			.gfield {
				margin-bottom: 40px;
			}
		}
		.gform_heading {
			margin-bottom: 40px;
		}
		.gform_title {
			font-weight: normal;
			font-size: 2rem;
		}
		.gform_fields {
			list-style: none;
			margin: 0;
			padding: 0;
		}
		.gform_validation_container {
			display: none;
		}
		.gform_footer {
			text-align: center;
			input {
				border-radius: 20px;
				padding: 5px 30px;
				border: 1px solid $font-color;
			}
		}
		.validation_message,
		.validation_error {
			color: red;
		}
	}
}