$primary-color: #CA2E37;
$light-grey: #F9F9F9;
$font-color: #272727;
$dark-grey: #94979a;

.container {
	max-width: 1100px;
	margin: 0 auto;
	padding: 0 10px;
}
.image-holder {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	padding-bottom: 60%;
}
.slider {
	position: relative;
	.image-holder {
		padding-bottom: 70%;
	}
	.slick-dots {
		position: absolute;
		left: 1rem;
		right: 1rem;
		bottom: 1rem;
		list-style: none;
		margin: 0 !important;
		display: flex !important;
		justify-content: flex-end;
	}
	.slick-active button {
		background: $primary-color;
	}
	button {
		width: 16px;
		height: 16px;
		border-radius: 0;
		margin: 0 4px;
		border: none;
		background: white;
		display: block;
		padding: 0;
		font-size: 0;
		box-shadow: 0 0 5px rgba(black, 0.5);
	}
}
.arrow-down {
	margin-top: 3rem;
	margin-bottom: 30vh;
	display: flex;
	justify-content: center;
	.arrow-trigger {
		display: inline-block;
		flex: 0;
		margin: 0 auto;
		width: auto;
		height: 50px;
		background: none;
		border: none;
		box-shadow: 0 0 0;
		outline: none;
		padding-top: 0;
		&:hover {
			text-decoration: underline;
		}
	}
	svg {
		width: 20px;
		display: inline-block;
		height: 20px;
		vertical-align: middle;
	}
}

.site-banner {
	background: #dc2500;
	position: relative;
	z-index: 99999;
	padding: 10px 0;
	@media (min-width: 640px) {
		padding: 20px 0;
	}
	.inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.site-logo {
		h1 {
			margin: 0;
			font-size: 30px;
			@media (min-width: 640px) {
				font-size: 40px;
			}
			@media (min-width: 1024px) {
				font-size: 66px;
			}
		}
		h5 {
			text-transform: uppercase;
			color: white;
			font-size: 14px;
			@media (min-width: 640px) {
				font-size: 18px;
			}
		}
		a {
			letter-spacing: 1px;
			font-family: 'Racing Sans One', cursive;
			color: white;
		}
	}
	.banner-social {
		a {
			display: inline-block;
			overflow: hidden;
			width: 30px;
			margin-left: 8px;
			img  {
				display: block;
				width: 100%;
			}
		}
	}
}
.article-list-title,
.notices-wrapper.notices-widget .notice-header .title {
	font-family: 'Racing Sans One', cursive;
	letter-spacing: 1px;
}

.trondheim-tv-wrap {
	background: #dc2500;
	.trondheim-tv {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	h2 {
		font-family: 'Racing Sans One', cursive;
		letter-spacing: 1px;
		color: white;
		font-size: 36px;
		margin: 0;
		@media (min-width: 640px) {
			font-size: 40px;
		}
	}
	img {
		width: 60px;
		@media (min-width: 640px) {
			width: 80px;
		}
	}
}