/**
/**
 * Naeringslivsavisa CSS - Sircon Opinionated Standards v2 (SOS-CSS)
 * Standing on the shoulders of normalize, twitter bootstrap, html5boilerplate and google web starter kit.
 * Compilation by Herman Hassel ( @agrabush )
 * @version 3.0.1
 * @usage Start coding at ~ line 1450
 */

/***************************************
 * Viewport, box-model and page wraps
 ***************************************/
@-webkit-viewport { width: device-width; }
   @-moz-viewport { width: device-width; }
	@-ms-viewport { width: device-width; }
	 @-o-viewport { width: device-width; }
		@viewport { width: device-width; }

/***************************************
 * Added fonts
 ***************************************/

@font-face {
	font-family: 'old/Guardian Egypt';
	src: url('old/GuardianEgyp-Bold.eot');
	src: url('old/GuardianEgyp-Bold.eot?#iefix') format('embedded-opentype'),
		 url('old/GuardianEgyp-Bold.otf')  format('opentype'),
	     url('old/GuardianEgyp-Bold.woff') format('woff'),
		 url('old/GuardianEgyp-Bold.ttf')  format('truetype'), url('old/GuardianEgyp-Bold.svg#GuardianEgyp-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
	font-family: 'old/Guardian Egypt';
	src: url('old/GuardianEgyp-Black.eot');
	src: url('old/GuardianEgyp-Black.eot?#iefix') format('embedded-opentype'),
		 url('old/GuardianEgyp-Black.otf')  format('opentype'),
	     url('old/GuardianEgyp-Black.woff') format('woff'),
		 url('old/GuardianEgyp-Black.ttf')  format('truetype'),
		 url('old/GuardianEgyp-Black.svg#GuardianEgyp-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}


/* --- OSWALD */

@font-face {
	font-family: 'Oswald';
	src: url('old/Oswald-Light.ttf')  format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
	font-family: 'Oswald';
	src: url('old/Oswald-Regular.ttf')  format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
	font-family: 'Oswald';
	src: url('old/Oswald-Bold.ttf')  format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* --- GUARDIAN SANS */
@font-face {
	font-family: 'old/Guardian Sans';
	src: url('old/GuardianSans-Regular-Web.eot');
	src: url('old/GuardianSans-Regular-Web.eot?#iefix') format('embedded-opentype'),
	     url('old/GuardianSans-Regular-Web.woff') format('woff'),
		 url('old/GuardianSans-Regular-Web.ttf')  format('truetype'),
		 url('old/GuardianSans-Regular-Web.svg#GuardianSans-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
	font-family: 'old/Guardian Sans';
	src: url('old/GuardianSans-Medium-Web.eot');
	src: url('old/GuardianSans-Medium-Web.eot?#iefix') format('embedded-opentype'),
	     url('old/GuardianSans-Medium-Web.woff') format('woff'),
		 url('old/GuardianSans-Medium-Web.ttf')  format('truetype'),
		 url('old/GuardianSans-Medium-Web.svg#GuardianSans-Medium') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
	font-family: 'old/Guardian Sans';
	src: url('old/GuardianSans-Black-Web.eot');
	src: url('old/GuardianSans-Black-Web.eot?#iefix') format('embedded-opentype'),
	     url('old/GuardianSans-Black-Web.woff') format('woff'),
		 url('old/GuardianSans-Black-Web.ttf')  format('truetype'),
		 url('old/GuardianSans-Black-Web.svg#GuardianSans-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
    font-family: 'League Gothic';
    src: url('leaguegothic-italic-webfont.eot');
    src: url('leaguegothic-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('old/leaguegothic-italic-webfont.woff2') format('woff2'),
         url('old/leaguegothic-italic-webfont.woff') format('woff'),
         url('old/leaguegothic-italic-webfont.ttf') format('truetype'),
         url('old/leaguegothic-italic-webfont.svg#league_gothicitalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'League Gothic';
    src: url('old/leaguegothic-regular-webfont.eot');
    src: url('old/leaguegothic-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('old/leaguegothic-regular-webfont.woff2') format('woff2'),
         url('old/leaguegothic-regular-webfont.woff') format('woff'),
         url('old/leaguegothic-regular-webfont.ttf') format('truetype'),
         url('old/leaguegothic-regular-webfont.svg#league_gothicregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/**********************************
 * Viewport & html5
 **********************************/

*, *:before, *:after {
	/* -webkit-touch-callout: none; /* enable for app-layout */
		box-sizing: border-box;
}

html {
	height: 100%;
	overflow-y: scroll;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
}
/* NOTE!
	If you're going to wrap the content in another div inside the body, remove body{min-height 100%;}
	and add body{height: 100%;} Then give the new wrap (#page or whatever) a {min-height: 100%};
*/
body {
	position: relative;
	margin: 0;
	min-width: 320px;
	min-height: 100%;
	/* 	-ms-touch-action: none;
			touch-action: none; /* enable for app-layout */
}

/***************************************
 * Html5 display definitions
 ***************************************/
article,
aside,
details,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
	display: block;
}

audio,
canvas,
progress,
video {
	display: inline-block;
	vertical-align: middle;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

.hidden,
[hidden],
template {
	display: none !important;
    visibility: hidden;
}

/*********************************
 * Text Level Semantics
 *********************************/

abbr[title] {
	border-bottom: 1px dotted;
}

b,
strong {
	font-weight: bold;
}

dfn {
	font-style: italic;
}

cite {
  font-style: normal;
}

mark {
	background: #ff0;
	color: #000;
}

figcaption,
small {
	font-size: 80%;
	padding-top: 4px;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

/***********************************
 * Embeds
 ***********************************/

img {
	border: 0;
	vertical-align: middle;
}

figure {
	position: relative;
}

figure img {
	display: block;
	width: 100%;
    height:auto;
}

figure figcaption {
	padding: 4px 10px 0;
	font-style: italic;
}

@media ( min-width: 600px ) {
	figure figcaption {
		position: absolute;
		left: 0; bottom: 0;
		display: block;
		width: 100%;
		padding: 12px 10px 16px;
		color: #fff;
		background-color: rgba( 0, 0, 0, .6 );
	}
}



svg:not(:root) {
	overflow: hidden;
}


/***********************************
 * Grouping
 ***********************************/

figure {
	margin: 0;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    padding: 0;
}

pre {
	overflow: auto;
}

code,
kbd,
pre,
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}

/***********************************
 * Forms
 ***********************************/

fieldset {
	border: 0;
	padding: 0;
	min-width: 0;
}
@-moz-document url-prefix() {
    fieldset {
        display: table-cell;
    }
}


legend,
input[type=checkbox],
input[type=radio],
button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

button,
input,
optgroup,
select,
textarea {
	color: inherit;
	font: inherit;
	margin: 0;
}

button,
select {
	text-transform: none;
}

input {
  line-height: normal;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

input[type=search] {
	-webkit-appearance: textfield;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
	-webkit-appearance: none;
}

textarea {
	overflow: auto;
	vertical-align: top;
	resize: vertical;
}

textarea[contenteditable] {
    -webkit-appearance: none;
}

optgroup {
	font-weight: bold;
}

textarea:focus,
input:not([type=button]):focus,
input:not([type=reset]):focus,
input:not([type=submit]):focus {
	outline:none;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	select:focus, textarea:focus, input:focus {
		font-size: 16px !important;
	}
}

/*************************************
 * Clickies (anchors and buttons)
 *************************************/
a,
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  background: transparent;
}

a:active,
a:hover {
  outline: 0;
}


a:focus {
	text-decoration: none;
}

a,
button,
input[type="button"],
input[type="reset"],
input[type="submit"],
a:after,
button:after,
input[type="button"]:after,
input[type="reset"]:after,
input[type="submit"]:after,
a:before,
button:before,
input[type="button"]:before,
input[type="reset"]:before,
input[type="submit"]:before {
	-webkit-transition: all 0.3s ease-out;
			transition: all 0.3s ease-out;
}

html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
}

/* this is the basic button profile, then add classes "primary, success, info, warning, danger or link" to change the view */
.btn,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	display: inline-block;
	cursor: pointer;
	overflow: visible;
	vertical-align: middle;
	margin-bottom: 0;
	padding: 6px 12px;
	border: 1px solid #ccc;
	border-radius: 4px;
	color: #333;
	background-color: #fff;
	background-image: none;
	font-weight: normal;
	text-align: center;
	text-decoration: none;
	outline: 0;
	white-space: nowrap;
	-webkit-user-select: none;
	   -moz-user-select: none;
		-ms-user-select: none;
			user-select: none;
}

.btn:focus,
button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
.btn:active:focus,
button:active:focus,
input[type="button"]:active:focus,
input[type="reset"]:active:focus,
input[type="submit"]:active:focus {
	box-shadow: 0 0 8px rgba(97, 157, 215, .7);
}

.btn:hover,
button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover,
.btn:focus,
button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus {
	color: #333;
	background-color: #ebebeb;
	border-color: #adadad;
}

.btn:active,
button:active,
html input[type="button"]:active,
input[type="reset"]:active,
input[type="submit"]:active {
	background-image: none;
	outline: 0;
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

/* primary */
.btn-primary,
html input.btn-primary {
	color: #fff;
	background-color: #428bca;
	border-color: #357ebd;
}
.btn-primary:hover,
html input.btn-primary:hover,
.btn-primary:focus,
html input.btn-primary:focus,
.btn-primary:active,
html input.btn-primary:active {
	color: #fff;
	background-color: #3276b1;
	border-color: #285e8e;
}

/* success */
.btn-success,
html input.btn-success {
	color: #fff;
	background-color: #5cb85c;
	border-color: #4cae4c;
}
.btn-success:hover,
html input.btn-success:hover,
.btn-success:focus,
html input.btn-success:focus,
.btn-success:active,
html input.btn-success:active {
	color: #fff;
	background-color: #47a447;
	border-color: #398439;
}

/* info */
.btn-info,
html input.btn-info {
	color: #fff;
	background-color: #5bc0de;
	border-color: #46b8da;
}
.btn-info:hover,
html input.btn-info:hover,
.btn-info:focus,
html input.btn-info:focus,
.btn-info:active
html input.btn-info:active {
	color: #fff;
	background-color: #39b3d7;
	border-color: #269abc;
}

/* warning */
.btn-warning,
html input.btn-warning {
	color: #fff;
	background-color: #f0ad4e;
	border-color: #eea236;
}
.btn-warning:hover,
html input.btn-warning:hover,
.btn-warning:focus,
html input.btn-warning:focus,
.btn-warning:active
html input.btn-warning:active {
	color: #fff;
	background-color: #ed9c28;
	border-color: #d58512;
}

/* danger */
.btn-danger,
html input.btn-danger {
	color: #fff;
	background-color: #d9534f;
	border-color: #d43f3a;
}
.btn-danger:hover,
html input.btn-danger:hover,
.btn-danger:focus,
html input.btn-danger:focus,
.btn-danger:active,
html input.btn-danger:active {
	color: #fff;
	background-color: #d2322d;
	border-color: #ac2925;
}

/* link */
.btn-link,
html input.btn-link {
	border-radius: 0;
	background-color: transparent;
}
.btn-link,
html input.btn-link,
.btn-link:hover,
html input.btn-link:hover,
.btn-link:focus,
html input.btn-link:focus,
.btn-link:active
html input.btn-link:active {
	border-color: transparent;
	box-shadow: none;
}
.btn-link:hover,
html input.btn-link:hover,
.btn-link:focus,
html input.btn-link:focus {
	text-decoration: underline;
	background-color: transparent;
}
/* button sizes */
.btn-lg,
html input.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px;
}
.btn-sm,
html input.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-xs,
html input.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-block,
html input.btn-block {
  display: block;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 5px;
}

/* disabled form controls */
fieldset[disabled] .btn,
fieldset[disabled] button,
fieldset[disabled] input,
.btn[disabled],
button[disabled],
input[disabled] {
	pointer-events: none;
	opacity: .65;
	cursor: not-allowed;
	box-shadow: none;
	background-color: #fff;
	border-color: #ccc;
}

/***********************************
 * Lists and Navigation
 ***********************************/

nav ul, .menu {
	list-style: none;
	margin: 0;
	padding: 0;
}
.menu a, .menu a:hover, .menu a:active, .menu a:focus,
nav a, nav a:hover, nav a:active, nav a:focus {
	text-decoration: none;
}


/***********************************
 *	Tables
 ***********************************/

table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}

table table {
	background-color: white;
}

table th {
	text-align: left;
}

table thead > tr > th, table thead > tr td, table tbody > tr > th, table tbody > tr td, table tfoot > tr > th, table tfoot > tr td {
	padding: 8px;
	line-height: 1.428571429;
	vertical-align: top;
	border-top: 1px solid #dddddd;
}

table thead > tr > th {
	vertical-align: bottom;
}

table thead:first-child tr:first-child th, table thead:first-child tr:first-child td, caption + table thead tr:first-child th, caption + table thead tr:first-child td, colgroup + table thead tr:first-child th, colgroup + table thead tr:first-child td {
	border-top: 0;
}

table tbody + table tbody {
	border-top: 2px solid #ddd;
}

table > tbody > tr:nth-child(odd) > td,
table > tbody > tr:nth-child(odd) > th {
	background-color: #f9f9f9;
}

@media (max-width: 840px) {
	.responsive-table {
		width: 100%;
		margin-bottom: 15px;
		overflow-x: scroll;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
			-ms-overflow-style: -ms-autohiding-scrollbar;
		border: 1px solid #ddd;
	}
	.responsive-table > table {
		margin-bottom: 0;
	}
	.responsive-table > table > thead > tr > th,
	.responsive-table > table > tbody > tr > th,
	.responsive-table > table > tfoot > tr > th,
	.responsive-table > table > thead > tr > td,
	.responsive-table > table > tbody > tr > td,
	.responsive-table > table > tfoot > tr > td {
		white-space: nowrap;
	}
}

/************************************
 * Helper Classes
 ************************************/
/* accessibility - show only for screenreaders */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* image replace */
.ir {
    background-color: transparent;
    border: 0;
    overflow: hidden;
}
.ir:before {
    content: "";
    display: block;
    width: 0;
    height: 150%;
}

/* clear floats */
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}

/* promote to composite layer (animation friendly) */
.promote-layer {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

/* text/element styling */
.caps {
	text-transform: uppercase;
}
.inline {
	display: inline-block;
}
.block {
	display: block;
}
.ingress {
	font-size: 20px;
	line-height: 1.1;
	margin-bottom: 6px;
}
@media ( min-width: 768px ) {
	.ingress {
		font-size: 20px;
		margin-bottom: 20px;
	}
}
.category-link {
/*	font-family: 'old/Guardian Egypt', sans-serif;*/
	font-family: 'old/Guardian Sans', sans-serif;

	font-weight: 900;
	letter-spacing: -1px;
	color: #dd2400;
}
.category-link:hover {
	color: #692632;
	text-decoration: none;
}

@media ( min-width: 1100px ) {
	.category-link {
		font-size: 20px;
	}
}


/*  */

.byline {
	border-top: 1px solid rgba(0,0,0,.4);
	padding-top: 4px;
	font-size: 80%;
    margin-bottom: 8px;
}
.byline span {
	display: block;
}
.byline .author {
	color: #000;
	font-weight: 700;
}
.byline .author:hover {
	color: #dd2400;
	text-decoration: none;
}
.magazine .byline {
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
}


/*  share strip */

.share-strip {
	color: #fff;
	background-color: #dd2400;
	height: 35px;
	margin-bottom: 10px;
}

.share-strip a {
	position: relative;
	float: left;
	display: block;
	height: 100%;
	color: #fff;
	border-right: 5px solid #fff3ef;
	font-weight: 700;
	width: 40px;
	overflow: hidden;
	color: #dd2400;
        padding-left: 40px;
}
.magazine .share-strip a {
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
	border-right: 5px solid #fff;
}
.share-strip a:after {
	content: '';
	position: absolute;
	top: 0; left: 0;
	display: block;
	width: 37px; height: 100%;
	background-repeat: no-repeat;
	background-position: 6px 3px;
	transition: .2s;
}
.share-strip a:before {
	content: '';
	position: absolute;
	top: 0; left: 0;
	display: block;
	width: 37px; height: 100%;
	background-repeat: no-repeat;
	background-position: 6px 3px;
	transition: .2s;
	opacity: 0;
	z-index: 2;
	transform: scale(.1);
}
.share-strip a:hover:before {
	opacity: 1;
	transform: scale(1);
}
.share-strip a:hover:after {
	transform: scale(3);
	opacity: 0;
	z-index: 3;
}

.share-strip a:last-child {
	border-right: 0;
}

.share-strip a:hover {
	color: #dd2400;
}
.share-strip .share-by-facebook:before,
.share-strip .share-by-facebook:after {
	background-image: url( 'old/fb.png' );
    background-position: 50% 45%;
}
.share-strip .share-by-twitter:before,
.share-strip .share-by-twitter:after {
	background-image: url('old/twtr.png');
    background-position: 50% 45%;
}
.share-strip .share-by-mail:before,
.share-strip .share-by-mail:after {
	background-image: url( 'old/icon-mail2.png' );
}

@media ( min-width: 480px ) {
	.share-strip a {
		width: auto;
		text-indent: 0;
		color: #fff;
		padding: 7px 20px 0 39px;
	}
	.share-strip a:hover {
		color: #fff;
	}
}



/* positioning */
.left {
	float: left;
	margin-right: 2%;
}
.right {
	float: right;
	margin-left: 2%;
}
.tl, .tr, .bl, .br, .tl-padded, .tr-padded, .bl-padded, .br-padded {
	position: absolute;
	margin: 0;
}
.tl { top: 0; left: 0; }
.tr { top: 0; right: 0; }
.bl { bottom: 0; left: 0; }
.br { bottom: 0; right: 0; }
.tl-padded { top: 20px; left: 20px; }
.tr-padded { top: 20px; right: 20px; }
.bl-padded { bottom: 20px; left: 20px; }
.br-padded { bottom: 20px; right: 20px; }

/* blocks */
.wrap {
	display: block;
	position: relative;
	width: 100%;
}

/* a scalable inner */
.inner {
	margin: 0 auto;
	padding: 0 10px;
	max-width: 1100px;
	position: relative;
}

/* responsively fit viewport blocks */
.view-4-1, .view-3-1, .view-16-9, .view-3-2, .view-4-3, .view-5-4, .view-1-1 {
	height: 0;
	overflow: hidden;
}

.view-1-1 {
	padding-bottom: 100%;
}

.view-5-4 {
	padding-bottom: 80%;
}

.view-4-3 {
	padding-bottom: 75%;
}

.view-3-2 {
	padding-bottom: 66.666666%;
}

.view-16-9 {
	padding-bottom: 56.25%;
}
.view-2-1 {
	padding-bottom: 50%;
}

.view-3-1 {
	padding-bottom: 33.333333%;
}

.view-4-1 {
	padding-bottom: 25%;
}

/* container for embeds - use with a .view class above */
p > iframe{width:100%; max-width:100%;}
.embed-container {
	position: relative;
	width: 100%;
	/* max-width: 100%; use width instead - max-width failed when moved out of 'content' */
	height: auto;
	margin-bottom: 12px;
}
.embed-container iframe,
.embed-container video,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0; left: 0;
	width: 100%; height: 100%;
}

/* wordpress image aligns/sizes */
.alignnone,
.alignleft,
.alignright,
.aligncenter {
	margin-bottom: 2%;
}
.aligncenter img {
	width: auto;
	max-width: 100%;
	margin: 0 auto;
}
.aligncenter figcaption {
	text-align: center;
}
@media (min-width: 660px) {
	.alignleft {
		float: left;
		width: 49%;
		margin: 0 2% 2% 0;
	}
	.alignright {
		float: right;
		width: 49%;
		margin: 0 0 2% 2%;
	}
}


/************************************
 * Bootstrap Dropdown
 ************************************/

#main-menu {
  position: relative;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropdown-menu {
  z-index: 1000;
  display: none;
}

.open > .dropdown-menu {
  display: block;
}

.open > a {
  outline: 0;
}

.dropdown-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 990;
}

/***********************************
 * Common Menus
 ***********************************/

/* #phone-menu .dl-menu */
.dl-menuwrapper {
	padding-top: 10px;
	width: 100%;
	max-width: 300px;
	float: right;
	position: relative;
	z-index: 1;
	-webkit-perspective: 1000px;
			perspective: 1000px;
	-webkit-perspective-origin: 50% 200%;
			perspective-origin: 50% 200%;
}
.dl-menuwrapper .dl-trigger {
	margin-left: 250px;
}
.dl-menuwrapper:first-child {
	margin-right: 100px;
}
.dl-menuwrapper button {
	width: 48px; height: 45px;
	background: #ccc;
	border: none;
	text-indent: -900em;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	outline: none;
}
.dl-menuwrapper button:hover,
.dl-menuwrapper button.dl-active,
.dl-menuwrapper ul {
	background: #aaa;
}
.dl-menuwrapper button:after {
	content: '';
	position: absolute;
	width: 68%;
	height: 5px;
	background: #fff;
	top: 10px; left: 16%;
	box-shadow: 0 10px 0 #fff, 0 20px 0 #fff;
}
.dl-menuwrapper ul {
	padding: 0;
	list-style: none;
	-webkit-transform-style: preserve-3d;
			transform-style: preserve-3d;
}
.dl-menuwrapper li {
	position: relative;
}
.dl-menuwrapper li a {
	display: block;
	position: relative;
	padding: 15px 20px;
	font-size: 16px;
	line-height: 20px;
	color: #fff;
	outline: none;
}
.no-touch .dl-menuwrapper li a:hover {
	background: rgba(255,248,213,0.1);
}
.dl-menuwrapper li.dl-back > a {
	background: rgba(0,0,0,0.1);
}
.dl-menuwrapper li.dl-back:after,
.dl-menuwrapper li > a:not(:only-child):after {
	position: absolute;
	top: 0;
	line-height: 50px;
}
.dl-menuwrapper li.dl-back:after {
	left: 10px;
	color: rgba(212,204,198,0.3);
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}
.dl-menuwrapper li > a:after {
	right: 10px;
	color: rgba(0,0,0,0.15);
}
.dl-menuwrapper .dl-menu {
	margin: 5px 0 0 0;
	position: absolute;
	width: 100%;
	opacity: 0;
	pointer-events: none;
	-webkit-transform: translateY(10px);
			transform: translateY(10px);
	-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
}
.dl-menuwrapper .dl-menu.dl-menu-toggle {
	-webkit-transition: all 0.3s ease;
			transition: all 0.3s ease;
}
.dl-menuwrapper .dl-menu.dl-menuopen {
	opacity: 1;
	pointer-events: auto;
	-webkit-transform: translateY(0px);
			transform: translateY(0px);
}
.dl-menuwrapper li .dl-submenu {
	display: none;
}
.dl-menu.dl-subview li,
.dl-menu.dl-subview li.dl-subviewopen > a,
.dl-menu.dl-subview li.dl-subview > a {
	display: none;
}
.dl-menu.dl-subview li.dl-subview,
.dl-menu.dl-subview li.dl-subview .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
	display: block;
}
.dl-menuwrapper > .dl-submenu {
	position: absolute;
	width: 100%;
	left: 0;
	margin: 0;
}

.dl-menu.dl-animate-out-1 {
	-webkit-animation: MenuAnimOut1 0.4s;
			animation: MenuAnimOut1 0.4s;
}
@-webkit-keyframes MenuAnimOut1 {
	50%		{	-webkit-transform: translateZ(-250px) rotateY(30deg); 				 }
	75%		{	-webkit-transform: translateZ(-372.5px) rotateY(15deg);	opacity: .5; }
	100%	{	-webkit-transform: translateZ(-500px) rotateY(0deg);	opacity: 0;	 }
}
@keyframes MenuAnimOut1 {
	50%		{			transform: translateZ(-250px) rotateY(30deg);				 }
	75%		{			transform: translateZ(-372.5px) rotateY(15deg);	opacity: .5; }
	100%	{			transform: translateZ(-500px) rotateY(0deg);	opacity: 0;	 }
}

.dl-menu.dl-animate-in-1 {
	-webkit-animation: MenuAnimIn1 0.3s;
			animation: MenuAnimIn1 0.3s;
}
@-webkit-keyframes MenuAnimIn1 {
	0%		{	-webkit-transform: translateZ(-500px) rotateY(0deg);	opacity: 0;	 }
	20%		{	-webkit-transform: translateZ(-250px) rotateY(30deg);	opacity: .5; }
	100%	{	-webkit-transform: translateZ(0px) rotateY(0deg);		opacity: 1;	 }
}
@keyframes MenuAnimIn1 {
	0%		{			transform: translateZ(-500px) rotateY(0deg);	opacity: 0;	 }
	20% 	{			transform: translateZ(-250px) rotateY(30deg);	opacity: .5; }
	100%	{			transform: translateZ(0px) rotateY(0deg);		opacity: 1;	 }
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-1 {
	-webkit-animation: SubMenuAnimIn1 0.4s ease;
			animation: SubMenuAnimIn1 0.4s ease;
}
@-webkit-keyframes SubMenuAnimIn1 {
	0%		{	-webkit-transform: translateX(50%);		opacity: 0; }
	100%	{	-webkit-transform: translateX(0px);		opacity: 1; }
}
@keyframes SubMenuAnimIn1 {
	0%		{			transform: translateX(50%);		opacity: 0; }
	100%	{			transform: translateX(0px);		opacity: 1; }
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-1 {
	-webkit-animation: SubMenuAnimOut1 0.4s ease;
			animation: SubMenuAnimOut1 0.4s ease;
}
@-webkit-keyframes SubMenuAnimOut1 {
	0%		{	-webkit-transform: translateX(0%);	opacity: 1; }
	100%	{	-webkit-transform: translateX(50%);	opacity: 0; }
}
@keyframes SubMenuAnimOut1 {
	0%		{			transform: translateX(0%);	opacity: 1; }
	100%	{			transform: translateX(50%);	opacity: 0; }
}

/* swap menu display definitions */
@media (min-width: 768px) {
	#phone-menu {
		display: none;
	}
}
/* use only #main-menu on oldIE */
.oldie #phone-menu {
	display: none;
}
.oldie #main-menu {
	clip: inherit;
	height: auto;
	margin: 0;
	overflow: visible;
	width: auto;
	position: static;
}


/************************************
 * Common Widgets
 ************************************/

/* searchform */
.searchform {
	/* padding: 15px 15px 15px; */
	/* height: 10px; */
	border-radius: 3px;
	/* position: relative; */
	opacity: 0;
	/* top: -10px; */
}
.searchform .search-field {
	display: block;
	width: 90%;
	height: 22px;
	float: left;
	padding: 3px 0 3px 12px;
	width:			calc(100% - 30px);
}
.searchform .search-btn {
	display: block;
	width: 24px;
	height: 22px;
	float: right;
	text-indent: -100px; /* the button text is so short 100px is enough */
	background-image: url('old/icon-search-gray.png');
	background-repeat: no-repeat;
	background-position: 3px 3px;
}


/* slider */
.sircon-slider .slide {
	position: absolute;
	top: 0;
	z-index: 1;
	display: block;
	width: 100%;
	opacity: 0;
}
.sircon-slider .slide.active {
	position: relative;
	z-index: 2;
	opacity: 1;
}


.sircon-slider figure {
	width: 100%; height: 0;
	overflow: hidden;
}
.sircon-slider .content {
	display: none;
	background-color: #000;
	padding: 20px 20px 50px;
}
@media (min-width: 768px) {
	.sircon-slider .content {
		width: 80%;
		max-width: 968px;
		position: absolute;
		top: 36%;

		min-height: 40%;
		left: 10%;
		background-color: rgba(0,0,0,.6);
	}
}
@media (min-width: 1268px) {
	.sircon-slider .content {
		width: 1000px;
		margin-left: -484px;
		left: 50%;
	}
}

.sircon-slider .active .content {
	display: block;
}
.sircon-slider .content .slide-title {
	color: #fff;
	font-weight: 400;
	font-size: 32px;
	text-transform: none;

}
.sircon-slider .content .slide-description {
	color: #fff;
	font-size: 18px;
}
.sircon-slider .content .slide-description .btn-right {
	bottom: 20px; right: 20px;
}

.sircon-slider .controls.arrows {
	display: none;
	width: 100%;
	position: absolute;
	top: 0; left: 0;
	z-index: 4;
	opacity: 0;
}
@media (min-width: 600px) {
	.sircon-slider .controls.arrows {
		display: block;
	}
	.sircon-slider .controls.arrows .control {
		position: absolute;
		top: -15px;
		display: block;
		width: 64px;
		height: 64px;
		background-repeat: no-repeat;
	}
}
.sircon-slider .controls.arrows .control.next {
	right: 10px;
	background-image: url('icon-arrow-right.png');
}
.sircon-slider .controls.arrows .control.previous {
	left: 10px;
	background-image: url('icon-arrow-left.png');
}
@media(min-width: 1160px) {
	#hero-unit .sircon-slider .controls.arrows,
        #hero-unit2 .sircon-slider .controls.arrows,
        #hero-unit3 .sircon-slider .controls.arrows,
        #hero-unit4 .sircon-slider .controls.arrows{
		width: 1160px;
		margin-left: -580px;
		left: 50%;
	} /* only affects hero unit - not sidebar-galleries */

}
/* indexes */
.sircon-slider .controls.indexes {

}
.sircon-slider .controls.indexes {

}

/* thumbnails */
.sircon-slider .controls.thumbnails {

}
.sircon-slider .controls.thumbnails .thumbnail {

}

/***********************************
 * Common Classes
 ***********************************/
/* one column */
	.columns-1 > *,
	.columns-2 > *,
	.columns-3 > *,
	.columns-4 > *,
	.columns-5 > *,
	.columns-6 > *,
	.columns-7 > *,
	.columns-8 > * {
		float: left;
		width: 100%;
		margin-bottom: 2%;
	}
	.columns-5 > *,
	.columns-6 > *,
	.columns-7 > *,
	.columns-8 > * {
		float: left;
		width: 49%;
		margin: 0 2% 2% 0;
	}
	.columns-5 > *:nth-child(2n),
	.columns-6 > *:nth-child(2n),
	.columns-7 > *:nth-child(2n),
	.columns-8 > *:nth-child(2n) {
			margin-right: 0;
	}
/* two columns */
@media (min-width: 480px) {
	.columns-2 > *,
	.columns-3 > *,
	.columns-4 > * {
		float: left;
		width: 49%;
		margin: 0 2% 2% 0;
	}
	.columns-2 > *:nth-child(2n),
	.columns-3 > *:nth-child(2n),
	.columns-4 > *:nth-child(2n) {
		margin-right: 0;
	}
	.columns-5 > *,
	.columns-6 > *,
	.columns-7 > *,
	.columns-8 > *,
	.columns-5 > *:nth-child(2n),
	.columns-6 > *:nth-child(2n),
	.columns-7 > *:nth-child(2n),
	.columns-8 > *:nth-child(2n) {
		width: 32%;
		margin-right: 2%;
	}
	.columns-5 > *:nth-child(3n),
	.columns-6 > *:nth-child(3n),
	.columns-7 > *:nth-child(3n),
	.columns-8 > *:nth-child(3n) {
		margin-right: 0;
	}
}
/* three columns */
@media (min-width: 600px) {
	.columns-3 > *,
	.columns-4 > *,
	.columns-3 > *:nth-child(2n),
	.columns-4 > *:nth-child(2n) {
		width: 32%;
		margin-right: 2%;
	}
	.columns-3 > *:nth-child(3n),
	.columns-4 > *:nth-child(3n) {
		margin-right: 0;
	}
	.columns-5 > *,
	.columns-6 > *,
	.columns-7 > *,
	.columns-8 > *,
	.columns-5 > *:nth-child(2n),
	.columns-6 > *:nth-child(2n),
	.columns-7 > *:nth-child(2n),
	.columns-8 > *:nth-child(2n),
	.columns-5 > *:nth-child(3n),
	.columns-6 > *:nth-child(3n),
	.columns-7 > *:nth-child(3n),
	.columns-8 > *:nth-child(3n) {
		width: 23.5%;
		margin-right: 2%;
	}
	.columns-5 > *:nth-child(4n),
	.columns-6 > *:nth-child(4n),
	.columns-7 > *:nth-child(4n),
	.columns-8 > *:nth-child(4n) {
		margin-right: 0;
	}
}
/* four columns && five columns */
@media (min-width: 768px) {
	.columns-4 > *,
	.columns-4 > *:nth-child(2n),
	.columns-4 > *:nth-child(3n) {
		width: 23.5%;
		margin-right: 2%;
	}
	.columns-4 > *:nth-child(4n) {
		margin-right: 0;
	}
	.columns-5 > *,
	.columns-6 > *,
	.columns-7 > *,
	.columns-8 > *,
	.columns-5 > *:nth-child(2n),
	.columns-6 > *:nth-child(2n),
	.columns-7 > *:nth-child(2n),
	.columns-8 > *:nth-child(2n),
	.columns-5 > *:nth-child(3n),
	.columns-6 > *:nth-child(3n),
	.columns-7 > *:nth-child(3n),
	.columns-8 > *:nth-child(3n),
	.columns-5 > *:nth-child(4n),
	.columns-6 > *:nth-child(4n),
	.columns-7 > *:nth-child(4n),
	.columns-8 > *:nth-child(4n) {
		width: 18.4%;
		margin-right: 2%;
	}
	.columns-5 > *:nth-child(5n),
	.columns-6 > *:nth-child(5n),
	.columns-7 > *:nth-child(5n),
	.columns-8 > *:nth-child(5n) {
		margin-right: 0;
	}
}
/* six columns */
@media (min-width: 860px) {
	.columns-6 > *,
	.columns-7 > *,
	.columns-8 > *,
	.columns-6 > *:nth-child(2n),
	.columns-7 > *:nth-child(2n),
	.columns-8 > *:nth-child(2n),
	.columns-6 > *:nth-child(3n),
	.columns-7 > *:nth-child(3n),
	.columns-8 > *:nth-child(3n),
	.columns-6 > *:nth-child(4n),

	.columns-7 > *:nth-child(4n),
	.columns-8 > *:nth-child(4n),
	.columns-6 > *:nth-child(5n),
	.columns-7 > *:nth-child(5n),
	.columns-8 > *:nth-child(5n) {
		width: 15%;
		margin-right: 2%;
	}
	.columns-6 > *:nth-child(6n),
	.columns-7 > *:nth-child(6n),
	.columns-8 > *:nth-child(6n) {
		margin-right: 0;
	}
}
/* seven columns */
@media (min-width: 960px) {
	.columns-7 > *,
	.columns-8 > *,
	.columns-7 > *:nth-child(2n),
	.columns-8 > *:nth-child(2n),
	.columns-7 > *:nth-child(3n),
	.columns-8 > *:nth-child(3n),
	.columns-7 > *:nth-child(4n),
	.columns-8 > *:nth-child(4n),
	.columns-7 > *:nth-child(5n),
	.columns-8 > *:nth-child(5n),
	.columns-7 > *:nth-child(5n),
	.columns-8 > *:nth-child(5n) {
		width: 12.5714285713%;
		margin-right: 2%;
	}
	.columns-7 > *:nth-child(7n),
	.columns-8 > *:nth-child(7n) {
		margin-right: 0;
	}
}
/* eight columns */
@media (min-width: 1000px) {
	.columns-8 > *,
	.columns-8 > *:nth-child(2n),
	.columns-8 > *:nth-child(3n),
	.columns-8 > *:nth-child(4n),
	.columns-8 > *:nth-child(5n),
	.columns-8 > *:nth-child(6n),
	.columns-8 > *:nth-child(7n) {
		width: 10.75%;
		margin-right: 2%;
	}
	.columns-8 > *:nth-child(8n) {
		margin-right: 0;
	}
}

/**
 * Responsive MediaElement.js container
 * Lots of !importants here because WP did the stupid and added default inline styles. :/
 */
.wp-video {
	width: 100% !important;
	height: auto !important;
	margin-bottom: 20px;
}
.mejs-container {
  width: 100% !important;
  height: 0 !important;
  padding-top: 56.85%;
}
.mejs-overlay, .mejs-poster {
  width: 100% !important;
  height: 100% !important;
}
.mejs-mediaelement video {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  width: 100% !important;
  height: 100% !important;
}


/******************************************************************************

					---=== Main Page Styles Start Here ===---

	All code above this line is expected to work with 99.9% of all webpages
	although you might want to change some of the colors.

*******************************************************************************/

/***************************************
 * Typography
 ***************************************/
/* all text color */
html,
button,
input,
select,
textarea {
    color: #000;
}

html {
	background-color: #ffffff;
	font: 16px/1.42857143 "Open Sans", sans-serif;
}

/* selections */
::-moz-selection {
    background: #b3d4fc;

    text-shadow: none;
}
::selection {
    background: #b3d4fc;
    text-shadow: none;
}

/* input placeholder text (should always be font-size 16; because of iOS form zoom bug) */
::-webkit-input-placeholder {
   font-size: 16px;
   color: #888;
}
:-moz-placeholder {
   font-size: 16px;
   color: #888;
}
::-moz-placeholder {
   font-size: 16px;
   color: #888;
}
:-ms-input-placeholder {
   font-size: 16px;
   color: #888;
}
::placeholder {
   font-size: 16px;
   color: #888;
}

/* text links */
a{
	color: #0645AD;
	text-decoration: none;
}
a:hover{
	color: #0645AD;
	text-decoration: underline;
}

.btn-link,
html input.btn-link {
	color: #dd2400;
	text-decoration: none;
}
.btn-link:hover,
html input.btn-link:hover,
a:focus,
.btn-link:focus,
html input.btn-link:focus {
	color: #692632;
	text-decoration: underline;
}
/* common margin */
h1, h2, h3, h4, h5, h6, p {
	margin: 0 0 20px;
}

/* headings */
h1, h2, h3, h4, h5, h6 {
	font-family: inherit;
	line-height: 1.1;
	color: #000;
	margin-bottom: 2px;
}

h1 {
	font-size: 40px;
    /*font-family: 'old/Guardian Egypt', serif;*/
	/*font-family: 'old/Guardian Sans', sans-serif;*/
	font-family: 'Merriweather', serif;
	font-weight: 900;
	letter-spacing: -1px;
	margin-bottom: 6px;
}
.sponsored h1 {
    font-family: 'Oswald', sans-serif;
}

@media ( min-width: 768px ) {
	h1 {
		font-size: 66px;
		margin-bottom: 20px;
	}
}

h2 {
	font-size: 22px;
	font-weight: 700;
}
h3 {

	font-size: 22px;
	font-weight: 700;
}
h4 {
	font-size: 18px;
	font-weight: 700;
}
h5 {

	font-size: 18px;
	font-weight: 400;
}
h6 {
	font-size: 14px;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-family: 'Open Sans', sans-serif;
}

h2 strong, h3 strong, h4 strong, h5 strong, h6 strong {
	font-weight: 400;
}
.sponsored.layout-magazine .content p {
    font-family: 'Times New Roman', Roman, serif;
    font-size: 18px;
}
/* in layout: magazine we change the font types up */
.magazine h1 {
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
}
@media ( min-width: 1100px ) {
	.magazine h1 {
		font-size: 78px;
	}
}
.magazine ,
.magazine h3,
.magazine h4,
.magazine h5,
.magazine p {
	font-family: 'Droid Serif', serif;
}

blockquote,
blockquote p,
.magazine blockquote p {
	font-family: 'Open Sans', sans-serif;
}

blockquote {
	position: relative;
	z-index: 1;
	padding: 24px;
	color: #555;
	font-size: 24px;
	font-style: italic;
	font-weight: 300;
}
@media ( min-width: 768px ) {
	blockquote {
		margin: 0 0 20px 20px;
		float: right;
		width: 66%;
	}
}

blockquote:before {
	content: "�";
	position: absolute;
	bottom: 0; right: 0;
	z-index: -1;
	display: block;
	width: 150px; height: 100px;
	padding-right: 36px;
	color: #e8e3dd;
	line-height: .8;
	font-family: "Times New Roman", serif;
	font-weight: 700;
	font-size: 240px;
}
blockquote:after {
	content: '';
	position: absolute;
	top: 0; left: 0;
	z-index: -2;
	display: block;
	width: 100%; height: 100%;
	border-top: 1px solid #fff;
	background-color: #fafafa;
	-webkit-box-shadow: 2px 2px 4px rgba(0,0,0,.2);
			box-shadow: 2px 2px 4px rgba(0,0,0,.2);
}

/***********************************
 * Header #top
 ***********************************/
#top {
	position: relative;
	z-index: 3;
}

/* header includes several sections, each with it's own inner */

/*----------------------------------
// Latest News
//---------------------------------*/

#top .scrolling-text {
	display: none;
}

@media ( min-width: 768px ) {
	#top .scrolling-text {
		position: relative;
		display: block;
		height: 40px;
		background-color: #fff;
}

	#top .scrolling-text-title {
		position: absolute;
		top: 0; left: 0;
		z-index: 2;
		height: 40px;
		padding: 0;
		margin: 0;
		color: #fff;
		background-color: #dd2400;
		font-family: 'League Gothic', sans-serif;
		font-size: 32px;
		padding: 1px 20px 0 4px;
		letter-spacing: 1px;
}

	#top .scrolling-text-collection {
		position: absolute;
		top: 0; left: 0;
		z-index: 1;
		display: block;
		width: 100%; height: 40px;
		margin: 0;
		padding: 0;

		overflow: hidden;
}

	#top .scrolling-text-item {
		position: absolute;
		top: 0; left: 0;
		display: block;
		opacity: 0;
	}

	#top .scrolling-text-item a {
		display: block;
		font-family: 'League Gothic', sans-serif;
		font-size: 30px;
		color: #000;
	}

	#top .scrolling-text-item a:hover {
		color: #dd2400;
		text-decoration: none;
	}

}

/*----------------------------------
// Top Ad Frame
//---------------------------------*/

.ad-frame /* #top-ad-frame */ {
	background-color: #fff3e4;
	padding: 12px;
	position: relative;
	clear: both;
}
.ad-frame .ad-frame {
	background-color: #fff;
	border: 1px solid #000;
	outline: 1px solid #fff;
	padding: 20px;
}

/*----------------------------------
// White Banner
//---------------------------------*/

#top > .white-banner {
	height: 55px;
	background-color: #fff;
}

#top > .white-banner .logo {
	position: absolute;
	top: 10px; left: 10px;
	display: block;
	width: 172px; height: 36px;
	background-image: url('old/logo-mobile.png');
	background-repeat: no-repeat;
	background-size: 172px 36px;
	z-index: 3;
}

@media (min-width: 768px) {
	#top > .white-banner {
		height: 110px;

		padding-top: 17px;
}

	#top > .white-banner .logo {
		width: 358px; height: 75px;
		background-image: url('old/logo.png');
		background-size: 358px 75px;
	}
}

/*----------------------------------
// Red Banner
//---------------------------------*/

#top > .red-banner {
	height: 55px;
	background-color: #dd2400;
}

#top > .red-banner .logo {
	position: absolute;
	top: 10px; left: 10px;
	display: block;
	width: 172px; height: 36px;
	background-image: url('old/logo-mobile.png');
	background-repeat: no-repeat;
	background-size: 172px 36px;
	z-index: 3;
}
#top > .red-banner .banner-social {
    height: 100%;
    position: absolute;
    top: 0px;
    right: 15px;
    padding-top: 20px;
    padding-bottom: 5px;
    text-align: right;
}
#top > .red-banner .banner-social a {
    margin-left: 8px;
}
#top > .red-banner .banner-social img {
    height: 100%;
    max-height: 30px;
    width: auto;
}

@media (min-width: 768px) {
	#top > .red-banner {
		height: 110px;
		padding-top: 17px;
	}

	#top > .red-banner .guide-logo,
	#top > .red-banner .logo {
		font-size: 0;
		top: 25px;
	}
	#top > .red-banner .logo:before {
		content: '';
		display: none;
	}
	#top > .red-banner .logo {
		width: 358px; height: 75px;
		background-image: url('old/logo.png');
		background-size: 358px 75px;
	}
}


#phone-menu {
	position: absolute;
	top: 10px; right: 10px;
	z-index: 2;
}
#phone-menu button {
	background-color: #f60;
}
#phone-menu button:hover,
#phone-menu button.dl-active,
#phone-menu ul {
	background-color: #f60;
}
#phone-menu > .dl-submenu {
	top: 60px; /* adjust this to prevent jumping sub-menus */
}


	/* Newsletter subscriber widget */
	#header-1{display:none;}
	@media(min-width:540px){
		#header-1{
			display:block;
			position:absolute;
			bottom:5px;
			right:10px;
			text-align:right;
		}
	}
	@media(min-width:768px){
		#header-1{
			bottom:26px;
		}
	}
	#header-1 .widget-title{
		font-size:12px;
		letter-spacing:0;
		text-transform:none;
		margin-bottom:5px;
	}
	#header-1 *{margin:0}
	#header-1 .wysija-paragraph{
		width:67%;
		float:left;
	}
	#header-1 .wysija-paragraph input{
		border-radius: 4px 0 0 4px;
		border: 1px solid #CCC;
		width: 100%;
		padding: 3px 5px 4px;
		font-size: 14px;
	}
	#header-1 .wysija-submit{
		position:absolute;
		bottom:0;
		right:0;
		margin:0;
		height:28px;
		padding-top:3px;
		border-radius:0 4px 4px 0;
		background:#E2D4C2;
		font-weight: 600;
	}
	#header-1 input,
	#header-1 input:hover,
	#header-1 input:active,
	#header-1 input:focus,
	#header-1 input::-webkit-input-placeholder{
		font-size:14px!important;
	}
	#header-1 input::-moz-placeholder{
		font-size:14px!important;
	}
	#header-1 input:-moz-placeholder{
		font-size:14px!important;
	}
	#header-1 input::-ms-input-placeholder{
		font-size:14px!important;
	}



/*----------------------------------
// Black Banner
//---------------------------------*/

#top > .black-banner {
	height: 40px;
	background-color: #777673;
	padding-top: 10px;
}

#top > .black-banner .icon-menu > * {
	float: left;
	margin-right: 41px;
}

#top > .black-banner .icon-menu .icon.home:before,
#top > .black-banner .icon-menu #category-menu > ul > li > a:before,
#top > .black-banner .icon-menu .icon.show-search:before {
	content: '';
	display: block;
	width: 0;
	height: 150%;
}

#top > .black-banner .icon-menu .icon.home,
#top > .black-banner .icon-menu #category-menu > ul > li > a,
#top > .black-banner .icon-menu .icon.show-search {
	display: block;
	overflow: hidden;
	background-repeat: no-repeat;
}
#top > .black-banner .icon-menu .icon.home:hover,
#top > .black-banner .icon-menu #category-menu > ul > li > a:hover,
#top > .black-banner .icon-menu .icon.show-search:hover {
	opacity: .8;
}

#top > .black-banner .icon-menu .icon.home {
	width: 22px; height: 20px;
	background-image: url( 'old/icon-home2.png' );
}
#top > .black-banner .icon-menu #category-menu > ul > li > a {
	width: 23px; height: 20px;
	background-image: url( 'old/icon-category-menu2.png' );
}
#top > .black-banner .icon-menu .icon.show-search {
	position: absolute;
	top: 0; right: 0;
	z-index: 2;
	opacity: 0;
	width: 23px; height: 22px;
	background-image: url( 'old/icon-show-search2.png' );
}

/* category menu has added functionality */
#top > .black-banner #category-menu .dropdown {
	position: relative;
}

#top > .black-banner #category-menu .dropdown-menu {
	display: none;
}

#top > .black-banner #category-menu .dropdown.open .dropdown-menu {
	display: block;
	position: absolute;
	top: calc( 100% + 12px ); left: 0;
	background-color: #777673;
	padding: 20px 0;
	width: 220px;
}

#category-menu .dropdown-menu li {
	display: block;
	margin-bottom: 2px;
}

#category-menu .dropdown-menu a {
	display: block;
	padding: 4px 20px;
	color: #fff;
	border-left: 3px solid #000;
	transition: 1s;
}

#category-menu .dropdown-menu a:hover {
	border-left: 3px solid #ccc;
	background-color: #222;
	transition: .2s;
}

/* search icon wrap has special functionality */
#top > .black-banner .search-icon-wrap {
	position: relative;
	display: block;
	width: 23px; padding: 0;
	height: 22px;
/*	overflow: hidden;*/
	margin: 0;
}

	/* Top black-banner should stick to the top of the screen when scrolled down so far that it has become hidden. */
	.black-banner-is-offscreen{padding-top:40px;}
	.black-banner-is-offscreen > #top > .black-banner{
		position:fixed;
		z-index:9001;
		left:0;right:0;
		top:0;
	}
	.black-banner-is-offscreen > #top > .black-banner > .inner{
		padding-right:40px;
	}
	/* .black-banner-is-offscreen > #top > .black-banner > .inner:after{
		display:block;content:"";
		position:absolute;
		right:0;
		top:-5px;
		height:30px;
		width:40px;
		background:url(old/logo-tiny-transparent.png) left 50% no-repeat;
	} */
	@media(min-width:520px){
		.black-banner-is-offscreen > #top > .black-banner > .inner{
			padding-right:240px;
		}
		/* .black-banner-is-offscreen > #top > .black-banner > .inner:after{
			width:210px;
			background-image:url(old/logo-small-transparent.png);
		} */
	}
	.admin-bar.black-banner-is-offscreen > #top > .black-banner{top:32px;}







/* ----------------------------------
// Main Menu
// ---------------------------------*/

#main-menu {
	display: none;
}

@media ( min-width: 768px ) {
	#main-menu {
		display: block;
	}

	#main-menu ul {

	}

	#main-menu li {
		display: inline-block;
	}
	#main-menu li:after {
		content: '';
		position: relative;
		top: 18px;
		float: left;
		display: block;
		width: 3px;
		height: 30px;
		border-left: 1px solid #fff;
		border-right: 1px solid #fff;
		background-color: #554e49;
		margin: 0 8px;
	}

	#main-menu li:last-child:after {
		display: none;
	}

	#main-menu a {
		float: left;
		display: block;
		height: 67px;

		color: #554e49;
		text-shadow: 0 1px 1px white;
		font-family: 'League Gothic', sans-serif;
		font-size: 32px;
		padding-top: 12px;
	}

	#main-menu a:hover {
		color: #dd2400;
	}

}


/***********************************
 * All Pages
 ***********************************/

#middle {
	position: relative;
	z-index: 1;
}

main {
	-webkit-transition: opacity 1s;
			transition: opacity 1s;
}

main > .content {
	margin-bottom: 20px;
}

.ajax-loading main {
	opacity: .1;
}

.facebook-wrap {
	padding: 8px;
	border: 1px solid #ccc;
	background-color: #fff;
	margin-bottom: 20px;
}
.facebook-wrap .fb-like-box {
	margin: 0 auto;
}

#sidebar {


}

#sidebar .widget {
	/* background-color: #f3f3f3; */
}

#sidebar .widget-title {
	height: 70px;
	padding: 25px 0 0 31px;
	margin: 0;
	color: #fff;
	font-size: 18px;
	text-transform: uppercase;
	background-color: #027ec0;
	border-radius: 3px 3px 0 0;
}

/* custom menu widget */
#sidebar .widget_nav_menu {

}

#sidebar .widget_nav_menu .menu {
	padding: 20px 0;
	border-radius: 0 0 3px 3px;
}


#sidebar .widget_nav_menu .menu > li {
	-webkit-transition: .7s;
			transition: .7s;
}
#sidebar .widget_nav_menu .menu > li:hover {
	background-color: #75c5f2;
	-webkit-transition: .1s;
			transition: .1s;
}

#sidebar .widget_nav_menu .menu > li > a {
	display: block;
	width: 100%;
	font-size: 16px;
	color: #2b2b2b;
	padding: 11px 0 11px 31px;
	-webkit-transition: .1s;
			transition: .1s;
}

#sidebar .widget_nav_menu .menu > li > a:hover {
	-webkit-transform: translate3d(-6px,0,0);
			transform: translate3d(-6px,0,0);
	-webkit-transition: .6s;
			transition: .6s;
}

/* Search Form */
#sidebar .searchform {
	background-color: #75c5f2;
}




/***********************************
 * Pages and posts (singles)
 ***********************************/

.page #middle {
	margin: 0 auto;
	max-width: 1100px;
}

@media ( min-width: 768px ) {
	.page.has-sidebar #middle #sidebar {
		width: 32%;
		float: right;
	}
	.page.has-sidebar #middle main {
		width: 66%;
		float: left;
	}

}



.page main,
.single main {
	margin-bottom: 50px;
}

/* use flexbox to keep #sidebar at the bottom for mobile devices */
.layout {
    position: relative;
	/*display: -webkit-flex;
	display:         flex;*/
	margin: 0 10px;
	-webkit-flex-flow: row wrap;
			flex-flow: row wrap;
}
.layout .featured-image {
	-webkit-order: 1;
			order: 1;
}
.layout #main {
	-webkit-order: 2;
			order: 2;
}

.layout #sidebar {
	width: 100%;
	-webkit-order: 3;
			order: 3;
}
/* change back when we get larger and want the sidebar to the right */
@media ( min-width: 768px ) {
	.layout {
		display: block;
		max-width: 1100px;
	}
	.layout #sidebar {
		width: 32%;
		float: right;
	}
	.layout main {
		width: 66%;
		float: left;
	}
}

@media (min-width: 1120px) {
	.layout {
		max-width: 1100px;
		margin: 0 auto;
	}
}

.layout.magazine {
	background-color: #fff;
	/* padding: 0 10px; */

}

.layout.magazine main {
	float: none;
	width: 100%;
	margin: 0 auto;
	max-width: 840px;
	padding: 0 10px;
}

.featured-image {
	width: 100%;
	position: relative;
	margin-bottom: 12px;
}
/* dgh-edit */
@media ( min-width: 600px ) {
	figure.featured-image figcaption {
		position: absolute;
		left: 0; bottom: 0;
		display: block;
		width: initial;
		padding: 12px 10px 16px;
		color: #fff;
		background-color: rgba( 0, 0, 0, .6 );
                z-index: 1000;
	}
}
@media ( max-width: 767px ) {
	.category-sponset-sak #middle > .featured-image:first-child,
	.layout.sponsored #main .featured-image,
	.sponsored > .featured-image,
        .sponsored > .magazine-header > .featured-image,
	.layout.sponsored > .mejs-container,
	article.sponsored .featured-image{
		padding-top:30px;
	}
}
.category-sponset-sak #middle > .featured-image:first-child:after,
.layout.sponsored #main .featured-image:after,
.sponsored > .featured-image:after,
.sponsored > .magazine-header > .featured-image:after,
.layout.sponsored > .mejs-container:after,
article.sponsored .featured-image:after {
	content: 'annonsørinnhold';
	position: absolute;
	top: 0; left: 0;
	z-index: 1;
	display: block;
	width: 100%; height: 30px;
	padding-top: 4px;
	color: #fff;
	text-align: center;
	font-size: 80%;
	letter-spacing: 2px;
	/*background-color: rgba( 0, 0, 0, .7 );*/
	background-color: rgba( 0, 0, 0, 1.0 );
}
@media ( min-width: 768px ) {
	.layout.sponsored #main  .featured-image:after,
	.sponsored > .featured-image:after,
        .sponsored > .magazine-header > .featured-image:after,
	.layout.sponsored > .mejs-container:after,
	article.sponsored .featured-image:after {
		background-color: rgba( 0, 0, 0, .7 );
	}
}
.dice.featured-image:after,
article.dice .featured-image:before {
	content: '';
	position: absolute;
	top: calc( 95% - 60px);
	left: calc( 95% - 60px );
	display: block;
	width: 60px;
	height: 60px;
	/* border: 30px solid transparent; */
	/* border-left: 48px solid #fff; */
        background-image: url('dice-sprite.png');
	-webkit-filter: drop-shadow( 0 0 10px rgba( 0, 0, 0, .4 ) );
			filter: drop-shadow( 0 0 10px rgba( 0, 0, 0, .4 ) );
}
.dice.featured-image:after {
    left: 5%;
}
.dice.dice-1.featured-image:after,
article.dice.dice-1 .featured-image:before {
	background-position: 0 0;
}
.dice.dice-2.featured-image:after,
article.dice.dice-2 .featured-image:before {
	background-position: -60px 0;
}
.dice.dice-3.featured-image:after,
article.dice.dice-3 .featured-image:before {
	background-position: -120px 0;
}
.dice.dice-4.featured-image:after,
article.dice.dice-4 .featured-image:before {
	background-position: -180px 0;
}
.dice.dice-5.featured-image:after,
article.dice.dice-5 .featured-image:before {
	background-position: -240px 0;
}
.dice.dice-6.featured-image:after,
article.dice.dice-6 .featured-image:before {
	background-position: -300px 0;
}

article.video .featured-image:before {
	content: '';
	position: absolute;
	top: calc( 50% - 50px);
	left: calc( 50% - 50px );
	display: block;
	width: 100px;
	height: 100px;
	/* border: 30px solid transparent; */
	/* border-left: 48px solid #fff; */
	background-image: url(bigplay.svg);
	-webkit-filter: drop-shadow( 0 0 10px rgba( 0, 0, 0, .4 ) );
			filter: drop-shadow( 0 0 10px rgba( 0, 0, 0, .4 ) );
}

article.video .featured-image:hover:before {
	background-position: 0 -100px;
}

/* three types of layouts */


/* normal traverses all */

@media ( min-width: 768px ) {
	.single .normal > .featured-image,
	.single .normal main {
		float: left;
		width: 66%;
	}

	/* wide just expands the featured image */
	.wide > .featured-image {
		width: 100%;
		clear: both;
		float: none;
	}
}

/* magazine layout is just different */
.magazine {

}

/****************************************
 * Related posts
 ****************************************/

.last-published-title,
.related-title {
	position: relative;
	padding: 3px 3px 3px 30px;
	background-image: url('old/icon-logo.png');
	background-repeat: no-repeat;
	background-position: 8px 8px;
	margin-bottom: 20px;
}
.last-published-title:after,
.related-title:after {
	content: '';
	position: absolute;
	top: 36px; left: 0;
	display: block;
	width: 100%; height: 1px;
	background-color: rgba(0,0,0,.2);
}
.last-published-snippets,
.related-posts {
	margin-top:43px;
    margin-bottom: 20px;

}
.last-published-snippets .news-item,
.related-posts .news-item {

}
.last-published-snippets .news-link,
.related-posts .news-link {
	color: #000;
}
.last-published-snippets .news-link:hover,
.related-posts .news-link:hover {
	text-decoration: none;
}
.last-published-snippets .news-image,
.related-posts .news-image {

}
.last-published-snippets .news-title,
.related-posts .news-title {
/*	font-family: 'old/Guardian Egypt', serif;*/
	/*font-family: 'old/Guardian Sans', sans-serif;*/
	font-family: 'Merriweather', serif;
	font-weight: 900;
}
.video-module-snippet-title{
	font-family: 'old/Guardian Sans', sans-serif;
}

.tab-content .themeblock-snippet .themeblock-title{
	font-weight:400;
}
.tab-content .themeblock-snippet:nth-child(1) .themeblock-title{
	font-weight:900;
    font-size: 46px;
}

.last-published-snippets .news-link:hover .news-title,
.related-posts .news-link:hover .news-title {
	color: #dd2400;
}
.last-published-snippets .news-excerpt,
.related-posts .news-excerpt {

}

@media ( min-width: 600px) {
	.related-posts .news-item {
		float: left;
		width: 32%;
		margin-right: 2%;
	}

	.related-posts .news-item:nth-child(3n) {
		margin-right: 0;
	}
}



/****************************************
 * Comments
 ****************************************/

.comments-field {
	background-color: #fff;
	padding: 20px 10px;
	border: 1px solid #444;
}

	#comments { /* comments-title */
/*	font-family: 'old/Guardian Egypt', serif;*/
	/*font-family: 'old/Guardian Sans', sans-serif;*/
		font-family: 'Merriweather', serif;
		font-weight: 900;
		margin-bottom: 30px;
	}

	.commentlist {
		padding: 0;
		margin: 0;
	}

		.comment {

			position: relative;
			list-style: none;
		}

			.comment-body {
				margin-bottom: 20px;
			}

				.comment-body .reply {
					padding-bottom: 36px;
				}

			.children {

			}

				.children .comment {

				}

		/* all comments */
		.comment-meta {
			position: relative;
			background-color: #fff3e4;
		}

			.comment-author {

			}

				.comment-author .avatar {
					-webkit-box-shadow: 2px 2px 2px rgba( 0, 0, 0, .2 );
							box-shadow: 2px 2px 2px rgba( 0, 0, 0, .2 );
				}

				.comment-author .fn .url {
					color: #dd2400;
					font-weight: normal;
				}

				.comment-author .fn .url:hover {
					color: #692632;
					text-decoration: none;
				}

			.comment-metadata {
				display: none;
			}

			.comment-metadata a:hover {
				text-decoration: none;
			}

		.comment-content {
			padding: 8px 4px;
		}

		.comment-reply-link {
			padding: 4px 8px;
			border-radius: 2px;

			color: #fff;
			background-color: #dd2400;
			float: right;
		}
		.comment-reply-link:hover {
			background-color: #692632;
		}


	#respond { /* comment-form-wrap */
		background-color: #fafafa;
		padding: 12px 8px 36px;
	}

	#respond h3 {
/*	font-family: 'old/Guardian Egypt', serif;*/
	/*font-family: 'old/Guardian Sans', sans-serif;*/
		font-family: 'Merriweather', serif;
		font-weight: 900;
		margin-bottom: 30px;
	}

		#commentform {

		}

		#respond #submit {
			padding: 4px 8px;
			border-radius: 2px;
			color: #fff;
			background-color: #dd2400;
			float: right;
		}

		#respond #submit:hover {
			background-color: #692632;
		}

	@media ( min-width: 768px ) {
		.comment-metadata {
			display: block;
			font-size: 80%;
			position: absolute;
			top: 4px; right: 4px;
		}
	}

/***********************************
 * Archives, searches and categories (polys)
 ***********************************/
.blog main,
.search main,
.archive main {

}

.poly-header {
	background-color: #dd2400;
	height: 96px;
	margin-bottom: 20px;
}
.poly-header .inner {
	height: 96px;
	display: flex;
	align-items: center;
}


.poly-header .poly-page-type {
	color: #fff;
	position: absolute;
	left: 20px;
	bottom: 55px;
}

.poly-header .poly-title {
	color: #fff;
	font-family: "Open Sans", sans-serif;
	font-weight: 300;
	font-size: 30px;
	margin: 0;
	/* position: absolute;
	right: 20px;
	bottom: 0px;
	line-height: 1; */
}


/* added special class */
a.clean,
a.clean:hover {
	text-decoration: none;
}

.archive .news-item {
	float: left;
	width: 49%;
	margin: 0 2% 2% 0;
}
/* 2nd small */
.archive .news-item:nth-of-type( 3n ) {
	margin-right: 0;
	/* clear: right; */
}
/* large version */
.archive .news-item:nth-of-type(3n - 2) {
	width: 100%;
}

.archive .news-link {
	color: #000;
}
.archive .news-link:active,
.archive .news-link:hover {
	text-decoration: none;
	color: #000;
}


.archive .news-image {

}

.archive .news-title {
/*	font-family: 'old/Guardian Egypt', serif;*/
	/*font-family: 'old/Guardian Sans', sans-serif;*/
	font-family: 'Merriweather', serif;
	font-size: 20px;
	font-weight: 400;
	letter-spacing: -1px;
	margin-bottom: 6px;
}

.archive .sponsored .news-title {
/*	font-family: 'old/Guardian Egypt', serif;*/
	/*font-family: 'old/Guardian Sans', sans-serif;*/
	font-family: 'Oswald', sans-serif;
	font-size: 20px;
	font-weight: 400;
	letter-spacing: -1px;
	margin-bottom: 6px;
}
.archive .news-item:nth-of-type(3n - 2) .news-title {
	font-size: 36px;
	margin-bottom: 15px;
}
.archive .news-link:hover .news-title {
	color: #dd2400;
}

.archive .news-excerpt {

}


@media ( min-width: 768px ) {

	/* reset margin for all used .news-item's and their nth-selectors */
	.archive .news-item,
	.archive .news-item:nth-of-type( 3n ) {
		margin: 0 0 2%;
	}
	/* we start floating base in the opposite direction */
	.archive .news-item {
		float: right;
		width: 32%;
	}

	/* every large should now be 2/3-size */
	.archive .news-item:nth-of-type(3n - 2) {
		width: 64%;
		float: left;
	}

	/* and then reverse floats for each 2nd cluster of 3 .news-items. */
	.archive .news-item:nth-of-type( 6n ),
	.archive .news-item:nth-of-type( 6n - 1 ) {
		float: left;
	}
	.archive .news-item:nth-of-type( 6n - 2 ) {
		float: right;
		clear: right;
	}

	.archive .news-title {
		font-size: 24px;
		margin-bottom: 0;
	}

	.archive .news-item:nth-of-type(3n - 2) .news-title {
		font-size: 56px;
		margin-bottom: 10px;
	}

	/* after 6 we do a strip of three */
	.archive .news-item:nth-of-type(7),
	.archive .news-item:nth-of-type(8),

	.archive .news-item:nth-of-type(9) {
		width: 32%;
		float: left;
		margin-right: 2%;
	}

	.archive .news-item:nth-of-type(7) .news-title,
	.archive .news-item:nth-of-type(8) .news-title,
	.archive .news-item:nth-of-type(9) .news-title {
		font-size: 30px;
		margin-bottom: 0;
	}

	.archive .news-item:nth-of-type(7) {
		clear: left;
	}
	.archive .news-item:nth-of-type(9) {
		margin-right: 0;
		clear: right;
	}
}

.snippet-line {
	border-bottom: 1px solid #ccc;
}

.snippet-line-link {
	padding: 8px 0;
}

.snippet-line-link:hover {
	background-color: rgba(0,0,0,.2);
	text-decoration: none;
}

.snippet-line-title {
	font-size: 16px;
/*	font-family: 'old/Guardian Egypt', serif;*/
	/*font-family: 'old/Guardian Sans', sans-serif;*/
	font-family: 'Merriweather', serif;
	font-weight: 400;
	float: right;
	width: calc(100% - 140px);
}
@media ( min-width: 768px ) {
	.snippet-line-title {
		padding-top: 10px;
		font-size: 18px;
	}
}

.snippet-line-image-wrap {
	float: left;
	width: 100px;
	margin: 0 20px;
}

.snippet-line-image {
	border: 1px solid #666;
	background-color: #000;
	margin: 0;
}



/***********************************
 * Front Page
 ***********************************/
.home main {
	padding: 10px;
}
@media (min-width: 1140px) {
	.home main {
		padding: 0;
	}
}

/* positioning */

.home main .hero-unit,
.home main .hero-unit2,
.home main .hero-unit3,
.home main .hero-unit4,
.home .articles-1-2,
.home .articles-2-1 {
	max-width: 1100px;
	margin: 0 auto;
}

/* styling */

/* all items */
.home .snippet {
	margin-bottom: 20px;
}
.home .snippet-featured-image {

}
.home .snippet-title ,
.guest-writer-title{
/*	font-family: 'old/Guardian Egypt', serif;*/
	/*font-family: 'old/Guardian Sans', sans-serif;*/
        font-family: 'Merriweather', serif;
	font-size: 30px;
	font-weight: 400;
	letter-spacing: -1px;
	margin-bottom: 6px;
}
.home .sponsored .snippet-title {
    font-family: 'Oswald', sans-serif;
}
.home .large .snippet-title {font-weight:900}

.home a.clean:hover .snippet-title {
	color: #dd2400;
}
.home .snippet-excerpt {
	/* dgh-edit
	margin: 0;
	*/
	display: none;
}

/* hero unit */
.home .snippet.hero-unit,
.home .snippet.hero-unit2,
.home .snippet.hero-unit3,
.home .snippet.hero-unit4{
	background-color: #e2d4c2;
	color: #000;
	margin-bottom: 20px;
}
.home .snippet.hero-unit2.sponsored {
    padding-top: 30px;
    position: relative;
}
.home .snippet.hero-unit2.sponsored:after {
    content: 'annonsørinnhold';
    position: absolute;
    top: 0; left: 0;
    z-index: 1;
    display: block;
    width: 100%; height: 30px;
    padding-top: 4px;
    color: #fff;
    text-align: center;
    font-size: 80%;
    letter-spacing: 2px;
    /*background-color: rgba( 0, 0, 0, .7 );*/
    background-color: rgba( 0, 0, 0, 1.0 );
}

.home .snippet.hero-unit .snippet-featured-image,
.home .snippet.hero-unit2 .snippet-featured-image{

}
.home .snippet.hero-unit3 .featured-image,
.home .snippet.hero-unit4 .featured-image{
    display: inline-block;
    width: 33%;
    vertical-align: middle;
    padding: 0 5px;
}
.home .snippet.hero-unit .snippet-title,
.home .snippet.hero-unit2 .snippet-title{
	color: #000;
	padding: 0 10px 10px 10px;
	font-size: 32px;
}

.home .snippet.hero-unit3 .snippet-title,
.home .snippet.hero-unit4 .snippet-title{
	color: #000;
	padding: 0 10px 10px 10px;
	font-size: 24px;
        display: inline-block;
        width: 66%;
        vertical-align: middle;
}

.home .snippet.hero-unit .snippet-excerpt,
.home .snippet.hero-unit2 .snippet-excerpt,
.home .snippet.hero-unit3 .snippet-excerpt,
.home .snippet.hero-unit4 .snippet-excerpt{
	padding: 0 10px 20px;

}

/* large */
.home .snippet.large {

}
.home .snippet.large .snippet-featured-image {

}
.home .snippet.large .snippet-title {
	font-size: 36px;
	margin-bottom: 15px;
}
.home .snippet.large .snippet-excerpt {
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
	padding: 6px 0;
}


.home .snippet.small {
	width: 49%;
	float: left;
	margin-right: 2%;
}
.home .articles-2-1 .snippet:nth-child(3),
.home .articles-1-2 .snippet:nth-child(3) {
	margin-right: 0; /* these are the rightmost .small articles pre tablet-portrait. */
}
.home .snippet.small .category-link {
	position: relative;
	display: block;
	width: 100%;
	margin-bottom: 11px;
	/* dgh-edit */
	display: none;
}
.home .snippet.small .category-link:after {
	content: '';
	position: absolute;
	top: calc( 100% + 2px ); left: 0;
	display: block;
	width: 100%; height: 1px;
	background-color: #000;
}
.home .snippet.small .snippet-featured-image {

}
.home .snippet.small .snippet-title {
	font-size: 20px;
}
.home .snippet-small .snippet-excerpt {

}

@media (min-width: 768px) {
	.home .snippet.hero-unit,
        .home .snippet.hero-unit2,
        .home .snippet.hero-unit3,
        .home .snippet.hero-unit4{
		padding: 10px;
	}
        .home .snippet.hero-unit2.sponsored {
            padding-top: 35px;
        }
	.home .snippet.hero-unit .snippet-title,
	.home .snippet.hero-unit .snippet-excerpt,
        .home .snippet.hero-unit2 .snippet-title,
	.home .snippet.hero-unit2 .snippet-excerpt,
        .home .snippet.hero-unit3 .snippet-title,
	.home .snippet.hero-unit3 .snippet-excerpt,
        .home .snippet.hero-unit4 .snippet-title,
	.home .snippet.hero-unit4 .snippet-excerpt{
		padding: 0;
	}

	.home .articles-1-2 .large,
	.home .articles-2-1 .large {
		width: 66%;
	}
	.home .articles-1-2 .small,
	.home .articles-2-1 .small {
		width: 32%;
	}
	.home .articles-1-2 .large,
	.home .articles-2-1 .small {
		float: left;
	}
	.home .articles-1-2 .snippet.small,
	.home .articles-2-1 .large,
	.home .articles-1-2 .snippet:nth-child(3n) {
		float: right;
		margin-right: 0;
	}

	.home .articles-2-1 .snippet.small,
	.home .articles-2-1 .snippet:nth-child(2n) {
		float: left;
		margin-left: 0;
	}

	.home .snippet.hero-unit .snippet,
        .home .snippet.hero-unit2 .snippet,
        .home .snippet.hero-unit3 .snippet,
        .home .snippet.hero-unit4 .snippet{

	}
	.home .snippet.hero-unit .snippet-title,
        .home .snippet.hero-unit2 .snippet-title{
		font-size: 66px;
	}
        .home .snippet.hero-unit3 .snippet-title,
        .home .snippet.hero-unit4 .snippet-title{
		font-size: 48px;
	}

	.home .snippet.large .snippet-title{
		font-size: 62px;
		margin-bottom: 10px;
	}

	.home .snippet.small .snippet-title {
		font-size: 30px;
		margin-bottom: 0;
	}
	.home .snippet.small {
		margin-bottom: 10px;
	}
}
.home .snippet.hero-unit .snippet-title,
.home .snippet.hero-unit2 .snippet-title,
.home .snippet.hero-unit3 .snippet-title,
.home .snippet.hero-unit4 .snippet-title{
    font-weight:900;
}
@media (min-width: 1100px) {
	.home .snippet.hero-unit .snippet-title,
        .home .snippet.hero-unit2 .snippet-title{
		font-size: 88px;
	}
        .home .snippet.hero-unit3 .snippet-title,
        .home .snippet.hero-unit4 .snippet-title{
		font-size: 66px;
	}
}


.recommended-module {
	background-color: #fff;
	padding: 20px;
	margin-bottom: 20px;
}

.recommended-module .recommended-module-title {
	font-size: 30px;
	font-weight: 300;
	margin-bottom: 10px;
}

.recommended-module .recommended-container {

}

.recommended-module .recommended-snippet {
	position: relative;
	padding-bottom: 30px;
	margin-bottom: 20px;
}

.recommended-module .recommended-featured-image {
	background-color: #000; /* cover up the base of images that are too small. */
}

.recommended-module .recommended-title {



}

.recommended-module .recommended-category-link {
	position: absolute;
	bottom: 0;
}

@media ( min-width: 600px ) {
	.recommended-module .recommended-snippet {
		position: relative;
		width: 32%;
		margin-right: 2%;
		float: left;
	}
	.recommended-module .recommmended-snippet:after {
		content: '';
		position: absolute;
		top: 0;	left: calc( 100% + 1%)
	}
	.recommended-module .recommended-snippet:nth-child(3n) {
		margin-right: 0;
	}
}


.job-market {
	padding: 20px 10px 40px;
	background-color: #e7e6e5;
	margin-bottom: 20px;
}
	.job-market-title {
		height: 47px;
		font-size: 30px;
		font-family: 'Open Sans', sans-serif;
		font-weight: 300;
		text-transform: lowercase;
		background-repeat: no-repeat;
	}
	@media ( min-width: 768px ) {
		.job-market-title {
			padding: 0 0 0 260px;
			background-image: url('old/logo-mobile.png');
		}
	}

	/* leading button */
	.job-market-entry {
		position: absolute;
		bottom: 10px; right: 10px;
	}

	/* item collection, application container */
	.jobs-collection {
		position: relative;
		height: 240px;
		padding: 10px;
		overflow: hidden;
		background-color: #fff;
	}

		/* each job */
		.job {
			position: absolute;
			opacity: 0;
			width: 220px;
			height: 220px;
			padding: 10px;
			border: 1px solid #ccc;
			background-color: #fff;
			transition: 0;
			-webkit-will-change: opacity, transform;
					will-change: opacity, transform;
		}

		.job:hover {
			text-decoration: none;
			-webkit-box-shadow: 2px 2px 3px rgba(0,0,0,.2);
					box-shadow: 2px 2px 3px rgba(0,0,0,.2);
		}

			.job-inner {

			}

				.job-featured-image {

				}

				.job-title {

				}
				.job:hover .job-title,
				.job:hover .job-excerpt {
					color: #dd2400;
				}

				.job-excerpt {
					color: #000;
    				position: absolute;
					bottom: 0;
					font-size: 80%;
}

	/* control wrapper */
	.job-controls {
		top: 0; left: 0;
		width: 100%;
		height: 0;
	}
		/* each button */
		.job-controls .control {
			position: absolute;
			top: 0;
			width: 48px; height: 100%;
			transition: 0;
		}
		.job-controls .control:hover {
			background-color: rgba( 0, 0, 0, .7 );

		}
		.job-controls .control:active {
			background-color: rgba( 255, 255, 255, .7 );
		}
		.job-controls .control:after {
			content: '';
			position: absolute;
			top: calc( 50% - 10px );
			display: block;
			width: 0; height: 0;
			border: 20px solid transparent;
			transition: 0;
			opacity: 0;
		}
		.job-controls .control:hover:after {
			opacity: 1;
		}
		/* left button */
		.job-controls .job-prev {
			left: 0;
		}
		.job-controls .job-prev:after {
			left: -4px;
			border-right: 14px solid white;
		}
		/* right button */
		.job-controls .job-next {
			right: 0;
		}
		.job-controls .job-next:after {
			right: -4px;
			border-left: 14px solid white;
		}


/*****************************
 * Reportasjen
 *****************************/

.reportage-module {
	display: none;
	position: relative;
	padding: 0;
	margin-bottom: 20px;
}
@media (min-width: 768px) {
	.reportage-module {
		display: block;
	}
}


.reportage-container {
	position: relative;
}

.reportage-snippet {
	position: absolute;
	top: 0; left: 0;
	display: block;
	width: 100%;
	background-color: #000;
}

.reportage-snippet:first-child {
	position: relative;
	z-index: 1;
}

.reportage-snippet .content {
	position: absolute;
	top: 0; right: 0;
	width: 33%;
	min-width: 350px;
	height: 100%;
	background-color: rgba(0,0,0,.8 );
	padding: 30px;
}


.reportage-snippet .content .category-intro {
	color: #fff;
	display: block;
	margin-bottom: 10px;
}

.reportage-snippet .content .reportage-title {
	color: #fff;
	font-weight: 300;
	font-size: 26px;
	font-family: 'old/Guardian Sans', sans-serif;
}
@media ( min-width: 900px ) {

	.reportage-snippet .content .reportage-title {
		font-size: 34px;
	}
}

@media ( min-width: 1100px ) {
	.reportage-snippet .content .reportage-title {
		font-size: 50px;
	}
}

.reportage-snippet .content .reportage-excerpt {
	position: absolute;
	bottom: 0;
	width: calc( 100% - 60px );
	padding: 20px 0;
	border-top: 1px solid #fff;
	border-bottom: 1px solid #fff;
	color: #fff;
}

.reportage-snippet .content:hover {
	text-decoration: none;
	background-color: rgba(255,255,255,.8);
}
.reportage-snippet .content:hover .category-intro,
.reportage-snippet .content:hover .reportage-title,
.reportage-snippet .content:hover .reportage-excerpt {
	color: #dd2400;
}
.reportage-snippet .content:hover .reportage-excerpt {
	border-top: 1px solid #dd2400;
	border-bottom: 1px solid #dd2400;
}

.reportage-module .control {
	position: absolute;
	left: 20px; bottom: 20px;
	z-index: 10;
	background-image: url( 'old/icon-reportage-next.png' );
	display: block;
	width: 48px; height: 48px;
}
.reportage-module .control:hover {
	opacity: .8;
}




@media (max-width: 767px) {

    .reportage-module {
        display: block;
    }

    .reportage-module .content .reportage-excerpt
    {
        display: none;
    }

    .reportage-module .content .category-intro {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 10px 20px;
        background-color: rgba(0,0,0,.8);
    }

    .reportage-snippet .content .reportage-title {
        background-color: rgba(0,0,0,.8 );
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px 10px 10px 40px;
        font-size: 16px;
    }
    .reportage-snippet .content {
        background-color: transparent;
    }

    .reportage-module .control {
        left: calc(50% - 24px);
        top: 30%;
    }
}


/**
 * Animation-constants, use these to create sliders.
 */

.current {
	z-index: 1;
}

.animating {
	z-index: 2;
}

/**
 * Tabs
 */
.tab-content > .tab-pane {
	display: none;
	visibility: hidden;
}
.tab-content > .active {
  display: block;
  visibility: visible;
}


/**********************************************************
 * Videos module:
 **********************************************************/

.video-module {
	background-color: #fff;
	padding: 20px;
	margin-bottom: 20px;
}

.video-module-title {
	font-size: 22px;
	margin-bottom: 8px;
}

.video-module-content {

}

.video-module-snippet {
	position: relative;
	margin-bottom: 20px;
}

.video-module-snippet-image {

}

.video-module-snippet-title {
	position: absolute;
	bottom: 0; left: 0;
	display: block;
	width: 100%;
	font-size: 20px;
	text-transform: none;
	padding: 10px 10px 10px 40px;
	color: #fff;
	background-color: rgba( 0,0,0,.8 );
	background-image: url('old/icon-play.png');
	background-repeat: no-repeat;
	background-position: 7px 8px;
}

@media ( min-width: 768px ) {
	.video-module-snippet {
		float: left;
		width: 32%;
		margin-right: 2%;
	}
	.video-module-snippet:nth-child(3n) {
		margin-right: 0;
	}
}











/**
 * Theme module
 */



.theme-module {
	padding: 0 20px 20px 20px;
	/*background-color: #e2d4c2;*/
	background-color: #efefef;
	margin-bottom: 20px;
        /* dgh-edit */
        /*background-image: url(logo-na-small-transparent.png);
        background-repeat: no-repeat;
        background-position: 99% 20px;*/
}

.theme-module-header {
    background-color: #de2300;
    padding: 10px 20px 10px 20px;
    margin: 0 -20px 20px -20px;
}

.theme-module-header img {
    height: 45px;
}

	.theme-module-title {
		position: relative;
		top: -15px;
		display: inline-block;
		height: 36px;
		color: #fff;
		background-color: #983c4b;
/*	font-family: 'old/Guardian Egypt', serif;*/
	/*font-family: 'old/Guardian Sans', sans-serif;*/
		font-family: 'Merriweather', serif;
		margin: 0 4px 4px 0;
		padding: 6px 10px;
	}

	#theme-tabs {
     	display: none;
		/*display: inline-block;*/
		margin: 0;
		padding: 0;
		list-style: none;
	}

	#theme-tabs li {
		display: block;
		float: left;
		margin: 0 04px 4px 0;
	}

	#theme-tabs li a {
		display: block;
		height: 36px;
		color: #fff;
		background-color: #8a8784;
		padding: 6px 10px;

		/* margin-bottom: 4px; */
	}

	#theme-tabs li a:active,
	#theme-tabs li a:hover {
		text-decoration: none;

	}
	#theme-tabs li a:hover {

		background-color: #983c4b;
	}
	#theme-tabs li.active a {
		background-color: #544e49;

		text-decoration: none;
}


.theme-module .tab-content {

}

.theme-module .tab-pane {

}

.themeblock-snippet {

}

.themeblock-title {
/*	font-family: 'old/Guardian Egypt', serif;*/
	/*font-family: 'old/Guardian Sans', sans-serif;*/
	font-family: 'Merriweather', serif;
	font-size: 18px;
}

.recommended-title {
/*	font-family: 'old/Guardian Egypt', serif;*/
	/*font-family: 'old/Guardian Sans', sans-serif;*/
	/*font-family: 'Oswald', sans-serif;*/
        font-family: 'Oswald', sans-serif;
	font-size: 18px;
}
.recommended-title {
	font-size:22px;
	font-weight:400
}
.recommended-container article:nth-child(2) .recommended-title {
	font-weight:900;
}

.themeblock excerpt {


}

/* mobile view */
.themeblock-snippet {
	float: left;
	width: 49%;
	margin: 0 2% 2% 0;


}
.themeblock-snippet .themeblock-excerpt {
	display: none;
}

.themeblock-snippet:first-child {
	width: 100%;
	margin-right: 0;
}
.themeblock-snippet:first-child .themeblock-title {
	font-size: 34px;
	margin-bottom: 10px;
}

.themeblock-snippet:first-child .themeblock-excerpt {
	display: block;
	padding: 10px 0;
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
}

.themeblock-snippet:nth-child(3) {
	margin-right: 0;
}

.themeblock-snippet:nth-child(n + 4) {
	display: none;
}

@media ( min-width: 768px ) {
	.themeblock-snippet,
	.themeblock-snippet:nth-child(3),
	.themeblock-snippet:nth-child(4),
	.themeblock-snippet:nth-child(n + 4) {
		display: block;
		width: 32%;
		margin-right: 2%;
	}
	.themeblock-snippet:nth-child(1),
	.themeblock-snippet:nth-child(2),
	.themeblock-snippet:nth-child(3n+3) {
		margin-right: 0;
	}

	.themeblock-snippet:first-child {
		width: 66%;
		margin-right: 2%;
	}

	.themeblock-snippet:nth-child(4) {
		clear: both;
	}

	.themeblock-snippet .themeblock-title {
		font-size: 24px;
	}

	.themeblock-snippet:first-child .themeblock-title {
		font-size: 36px;
	}
}




.guest-writer-module {
	position: relative;
	padding: 20px;
	background-color: #fff;
	margin-bottom: 20px;
	-webkit-box-shadow: 2px 2px 4px rgba(0,0,0,.1);
			box-shadow: 2px 2px 4px rgba(0,0,0,.1);
}
.guest-writer-module .content a:active,
.guest-writer-module .content a:hover,
.guest-writer-module .content a:hover .guest-writer-title {
	text-decoration: none;
	color: #dd2400;
}

.guest-writer-module .category-link {
	display: inline-block;
	margin-bottom: 20px;
}

.guest-writer-module .guest-writer-archive {
	position: absolute;
	top: 20px; right: 20px;
	display: block;
	color: #dd2400;
}

.guest-writer-title {
	font-weight: 900;
	font-size: 42px;
	margin-bottom: 16px;
}

.guest-writer-byline {
	font-weight: 700;
}

@media ( min-width: 480px ) {
	.guest-writer-module .featured-image {
		width: 49%;
		float: left;
	}
	.guest-writer-module .content {
		width: 49%;
		float: right;
	}
}

@media ( min-width: 768px ) {
	.guest-writer-module .featured-image {
		width: 32%;
		float: left;
	}
	.guest-writer-module .content {
		width: 64%;
		float: right;
	}
	.guest-writer-title {
		background-image: url('old/icon-guest-writer-quote.png');
		background-repeat: no-repeat;
		background-position: 0 10px;
		padding-left: 60px;
		font-size: 56px
	}
}


/*************************************************
 * Article lists
 *************************************************/

/* outer */
.article-list {
        background-color: #dd2400;
	/* background-color: #544d48; */
	margin-bottom: 20px;
}

.article-list-title {
	position: relative;
	margin: 0;
	color: #fff;
	text-transform: uppercase;
	padding: 2px 20px 2px 40px;
	font-size: 16px;
	font-weight: 700;
	line-height: 22.8571px;
	background-position: 5px 3px;
	letter-spacing: normal;
}

/* inner */
.article-list-container {
	padding: 0;
	margin: 0;
}

.article-list-snippet {
	display: block;
}

.article-list-snippet-link {
	position: relative;
	display: block;
	background-color: #fff;
	padding: 4px;
	padding-right: 14px;
}

.article-list-snippet:nth-child( odd ) .article-list-snippet-link {
	background-color: #e7e6e5;

}

.article-list-snippet-link:hover .article-list-snippet-title {
	color: #dd2400;
}

.article-list-snippet-time {
	position: absolute;
	top: 6px;
	left: 5px;
	font-size: 14px;
	font-weight: 400;
	color: #332b25;
	display: block;
}

.article-list-snippet-title {
	font-size: 14px;
	font-weight: 400;
	color: #000;
	padding-left: 66px;
	text-decoration: underline;
	display: block;
    white-space: pre;
    overflow: hidden;
}
.article-list-snippet-title.sponsored:before {
    content: "(Annonse): ";
}

/* we hide some lists from mobile, so users don't think the page has reached it's end */

.modular-field > .last-published,
.modular-field > .ad-position {
	display: none;
}


@media ( min-width: 600px ) {
	.modular-field > .ad-position {
		display: block;
		width: 32%;

		float: right;
	}
	.modular-field > .most-viewed {
		display: block;
		width: 66%;
		float: left;
	}
}
@media ( min-width: 1000px) {
	.modular-field > .last-published,
	.modular-field > .ad-position,
	.modular-field > .most-viewed {
		display: block;
		width: 35%;
		float: left;
		margin-right: 2%;
		clear: none;
	}
	.modular-field > .ad-position {
		width: 26%;
	}
	.modular-field > .most-viewed {
		margin-right: 0;
	}

}

/***********************************
 * Footer #bottom
 ***********************************/

/*
 #middle {
	padding-bottom: 200px;
}

.home #middle {
	padding-bottom: 0;
}

*/
#bottom {
	/* position: absolute; */
	background-color: #777673;
	padding-top: 20px;
	/* bottom: 0; */
	width: 100%; height: 500px;
	z-index: 2;
}

#bottom #footer,
#bottom #footer a,
#bottom #footer a:hover {
	color: #fff;
}

.footer-responsible {
	font-size: 80%;
}

.footer-responsible {
	color: #ddd;
	max-width: 530px;
	margin: 0 auto;
}
.footer-responsible a {
	color: #fff;
}

.footer-persons {
	margin-bottom: 10px;
}

.footer-person {
	margin-bottom: 10px;
	margin-right: 20px;
	font-size: 14px;
	font-weight: bold;
}

.footer-person-column {
	margin-right: 10px;
}

.footer-person-column:last-child {
	margin-right: 0;
}

.footer-person:last-child {
	margin-right: 0;
}

.footer-person-title {
	margin-right: 5px;
}

.responsible-editor {
	padding-left: 75px;
	background-repeat: no-repeat;
	background-image: url('old/redaktoransvar.jpg');
	background-position: 7px 6px;
}

.last-word {

}

@media (min-width: 568px) {
	.footer-persons {
		display: flex;
		justify-content: center;
	}

	#bottom {
		height: 360px;
	}
}



/*
@media (min-width: 660px) {
	#middle {
		padding-bottom: 100px;
	}
	#bottom {
		height: 100px;
	}
}

*/
#colophon {
	font-size: 80%;
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 20px;
}
#colophon,
#colophon a {
	color: #b5b5b5;
	text-decoration: none;
}
#colophon p {
	margin-bottom: 0;
	text-align: center;
}
#colophon a:hover {
	text-decoration: underline;
}

/*************************************
 * Queries for XHDPR-screens.
 *************************************/

/* all xhdpr-screens. */
@media
( -webkit-min-device-pixel-ratio: 2 ),  /* Osx Safari, Ios Chrome, Ios Safari, Ios Opera Mini */
(         min-device-pixel-ratio: 2 ),  /* noone, but future-proof if Apple decides not to support resolution, but remove prefix */
(            min-resolution: 192dpi ),  /* Win7 IE9-IE11, WinMobile-IE10, Android Opera Mini */
(            min-resolution: 2dppx  )   /* Win-Osx-Android Chrome, Win-Osx-Opera, Win-Osx-Android-Firefox */ {
	/* add all larger images here / hd-sprites */
	.no-svg .logo {
		background-image: url('mobile-logo-2x.png');
	}
}

/* tablet+ xhdpr views */

@media
( -webkit-min-device-pixel-ratio: 2 ) and ( min-width: 768px ),
(         min-device-pixel-ratio: 2 ) and ( min-width: 768px ),
(            min-resolution: 192dpi ) and ( min-width: 768px ),
(            min-resolution: 2dppx  ) and ( min-width: 768px ) {
	/* add even larger images here / hd-sprites */
	.no-svg .logo {
		background-image: url('logo-2x.png');
	}
}

/* even larger xhdpr views */
@media
( -webkit-min-device-pixel-ratio: 2 ) and ( min-width: 1300px ),
(         min-device-pixel-ratio: 2 ) and ( min-width: 1300px ),
(            min-resolution: 192dpi ) and ( min-width: 1300px ),
(            min-resolution: 2dppx  ) and ( min-width: 1300px ) {
	/* add even even larger images here / hd-sprites */
}

/***********************************
 * Semi-graceful degradation
 ***********************************/
.oldie body { /* set body wide */
	min-width: 960px;
}
.oldie #top { /* set top wide and static (in case it's prettified) */
	min-width: 960px;
	position: static;
}
.oldie #middle { /* no upper padding (in case #top is fixed or absolute) */
	padding-top: 0;
}


/************************************
 * Wordpress admin-bar defaults
 ************************************/

#wpadminbar {
	position: fixed;
}

html.has-admin-bar {
	height:	calc(100% - 46px);
}

@media (min-width: 782px) {
	html.has-admin-bar {
		height:	calc(100% - 32px);
	}
}

/***********************************
 * Ads
 ***********************************/
.ad-position img {
	max-width: 100%;
}

.ad-position {
	padding: 0;
}

main .collection > .ad-position {
	float: left;
	width: 100%;
}

#top-header-banner {
	float: none;
}

#middle .inner-ad-container {
	margin-bottom: 20px;
}

.inner-ad-container {
	position: relative;
}

/***********************************
 * Print styles
 ***********************************/

 @media print {
    * {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }
    a,
    a:visited {
        text-decoration: underline
    }
    pre,
    blockquote {
        border: 1px solid #999;
        padding: 0 5px;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    tr,
    img {
        page-break-inside: avoid;
    }
    img {
        max-width: 100% !important;
    }

	@page {
		margin: 0.5cm;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}
}



/* Fixing shoddy work - galleries are dysfunctional, visually */
.gallery{
	position:relative;
}
.gallery figure{margin-bottom:20px;}
.gallery img{height:auto;}
/* single column */
	.gallery-columns-1 figure:nth-child(n){
		width:100%;
	}

/* two columns */
	.gallery-columns-2 figure:nth-child(n){
		float:left;
		margin-right:20px;
		width:calc((100% - 20px) / 2);
	}

/* three columns */
	.gallery-columns-3 figure:nth-child(n){
		float:left;
		margin-right:20px;
		width:calc((100% - 40px) / 3);
	}
	.gallery-columns-2 figure:nth-child(2n),
	.gallery-columns-3 figure:nth-child(3n){
		margin-right:0;
	}
	.gallery-columns-2 figure:nth-child(2n+1),
	.gallery-columns-3 figure:nth-child(3n+1){clear:left;}




/* Quickfix paralax */
td.parallax-header{display:none;}

/* Proper paralax - sircon style */
	.sircon-fullwidth-paralax{
		position:relative;
		margin-bottom:20px;
		height:450px;
	}
	.sircon-fwp-overflowhider{
		overflow:hidden;
		position:fixed;
		left:0;
	}
	.sircon-fwp-img{
		position:absolute;
		top:0;left:0;right:0;
		background-size:cover;
		-webkit-transform:translate3d(0,0,0);
		-moz-transform:translate3d(0,0,0);
		transform:translate3d(0,0,0);
	}
	.sircon-fwp-img > img{margin:0;}
	.sircon-fwp-img.has-bg-img > img{
		opacity:0;
		width:100%;
	}

/*
Custom share-strip redesign
--------------------------- */
.share-strip {
    display: block !important;
    float: right;
    top: -45px;
    position: relative;
}

.share-by-facebook {
    background:#3a5795;
}
.share-by-twitter {
    background:#55acee;
}

/*
Magazine Layout Title and Ingress styles
---------------------------------------- */
.layout.magazine{
    position:relative;
    max-width:none !important;
}

.headline-ingress {
    /*
    top:-285px;
    max-width:100%;*/
    background:#000;
    position:relative;
    left:-10px;
    width:calc(100% + 20px);
}
.headline-ingress .ingress {
    padding:0 15px 15px 15px;
    color:#FFF;
    display:none;
    font-size:16px;
}

.magazine-headline h1 {
    color: #FFF;
    padding: 15px;
    font-family: 'old/Guardian Sans', serif;
    font-size: 22px;
    font-weight: 700;
}
.headline-ingress .ingress {
    display:block;
}

@media(min-width:480px) {
    .headline-ingress {
        position:absolute;
        bottom:0;
        left:0;
        max-width:90%;
        background:rgba(0,0,0,0.7);
    }
    .headline-ingress .ingress {
        display:block;
    }

    .magazine-headline h1 {
        color: #FFF;
        padding: 15px;
    }

    .magazine-headline h1 {
        font-size: 24px;
    }
}
@media(min-width:720px) {
    .headline-ingress {
        bottom:20px;
        max-width:680px;
    }
    .magazine-headline h1 {
        font-size: 48px;
        font-weight: 900;
    }
    .headline-ingress .ingress {
        display:block;
        font-size:20px;
    }

}
@media(min-width:1400px) {
    .headline-ingress {
        display:block;
        max-width:960px;
    }

    .magazine-headline h1 {
        left: 0;
        color: #FFF;
        padding: 15px;
    }

    .magazine-headline h1 {
        font-size: 88px;
    }
}





/*
*/
.sponsored{position:relative;}
.fullwidth-header{margin-bottom:0;}






/* new layout for single content - actually possible to comprehend this time ! */
.byline-area{position:relative;}
.byline-area .share-strip{
    position:absolute;
    top:5px;
    right:0;
}
/* magazine */
.layout-magazine{
    max-width:none;
    margin-left:0;
    margin-right:0;
}
.layout-magazine #main{
    float:none;
    margin:0 auto;
    max-width:746px;
    padding:0 10px;
}
.magazine-header{position:relative;}
.magazine-header .extra-intro{
    position:relative;
    padding:20px;
	margin-top:-55px;
}
.magazine-header .extra-intro:after{
	content:"";
	position:absolute;
	top:55px;
	left:0;
	right:0;
	bottom:0;
	background:#000;
}
.magazine-header .extra-intro a,
.magazine-header .extra-intro h1,
.magazine-header .extra-intro p{
    color:#fff;
    position:relative;
    z-index:9001;
    margin:0;
}
.magazine-header .extra-intro a{font-weight:600; text-decoration:underline;}
.magazine-header .extra-intro h1{
    margin-bottom:15px;
    font-size:26px;
    font-weight:600;
    text-transform: uppercase;
}
.sponsored .magazine-header .extra-intro h1{
    text-transform: none;
}
.magazine-header .extra-intro p{font-size:14px;}

@media(min-width:480px){
    .magazine-header .extra-intro h1{
        font-size:26px;
        font-weight:900;
    }

    .magazine-header .extra-intro p{font-size:18px;font-weight: lighter;}
}
@media(min-width:720px){
    .magazine-header .extra-intro h1{
        font-size:42px;
        font-weight:900;
    }

    .magazine-header .extra-intro p{}
}
@media(min-width:960px){
    .magazine-header .extra-intro h1{
        font-size:62px;
    }
    .magazine-header .extra-intro p{}
    .magazine-header .extra-intro h1{max-width:65%;}
    .magazine-header .extra-intro a,
    .magazine-header .extra-intro p{max-width:50%;}
}
@media(min-width:1280px){

    .magazine-header .extra-intro h1,
    .magazine-header .extra-intro p{
        margin:0 auto;
        /*max-width:726px;*/
    }
    .magazine-header .extra-intro h1{text-align:center;margin-bottom: 10px;}
    .magazine-header .extra-intro p{padding-right:40px;}
}
.magazine-header .featured-image{margin-bottom:0;}
.magazine-header .featured-image:before{
    content:"";
    display:block;
    position:absolute;
    top:50%;
    bottom:0;
    left:0;
    right:0;
    z-index:999;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0+0,1+100;Neutral+Density */
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */

}

@media(min-width:720px){
	.magazine-header .featured-image:before{
		top:70%;
	}
}

@media(min-width:1280px){
	.magazine-header .featured-image:before{
		top:85%;
	}
}



/* fix sidebar */
#sidebar .last-published-snippets{margin-top:0;}


.fb-social-plugin{margin-bottom:14px;}




/***********************************
 * Full-width-youtube
 ***********************************/

.sircontheme-fullwidth-youtube {
	margin-bottom:20px;
}

.fwy-wrapper {
	position: absolute;
	left:0;
    overflow: hidden;
}
.fwy-wrapper iframe{
	width: 100%;
	margin-top:-35px;
	height: calc(100% + 70px);
}

textarea{max-width:100%;}

#custom-volume {
	position: absolute;
    right: 0;
    padding: 5px;
    background-color: rgba(255,255,255,0.5);
    cursor: pointer;
}



.frontpage-snippet-with-notices-wrapper {
	max-width: 1100px;
    margin: 0 auto;
}

.clear {
	clear: both;
}

.home .snippet.hero-unit.has-notices {
	width: calc(((100% - 20px) / 3) * 2);
	float: left;
	margin-right: 20px;
}
.home .snippet.notices-snippet {
	width: calc((100% - 20px) / 3);
	float: left;
}

@media(max-width: 1140px) {
	.home .snippet.hero-unit.has-notices,
	.home .snippet.notices-snippet {
		width: 100%;
		height: auto!important;
		margin: 0 0 20px;
		float: none;
	}
}

.sircon-youtube-ad.fullsource {
	overflow: hidden;
}

.sircon-youtube-ad.fullsource iframe{
	max-height: 100%;
	height: 100%;
	width: 100%;
}



/*
	Coupon layout simplifications
*/

.coupon-layout .fb-social-plugin,
.coupon-layout #bottom {
	display: none;
}
