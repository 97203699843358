@import "../style/style-3.0.4.css";
@import url('https://fonts.googleapis.com/css?family=Abel');

$slick-font-path: "../fonts/";
$slick-loader-path: "../images/";
@import "slick.scss";
//@import "slick-theme.scss";

@import "global";
@import "companies";
@import "category-tags";

@media (max-width:768px) {
	header#top {
		.inner.ad-position {
			background: #fff;
			position: relative;
			z-index: 1;
			padding-bottom: 1px;
		}
	}

	.mobile-nav-toggle {
		position: absolute;
		top: 10px;
		right: 0;

		div {
			width: 30px;
			height: 3px;
			background-color: white;
			margin: 0 15px 5px;
		}
	}
	
	#top {
		// >.site-banner {
		// 	position: relative;
		// 	z-index: 3;
		// 	min-height: 55px;
		// 	height: auto;
		// }
		// >.site-banner .banner-social {
		// 	padding-top: 13px;
		// 	height: 55px;
		// }
		>.black-banner {
			padding: 0;
			position: relative;
		}
		.black-banner .inner {
			position: relative;
			z-index: 3;
			padding-top: 10px;
			background: #777673;
			padding-bottom: 1px;
		}
		#mobile-menu {
			position: relative;
		}
		.mobile-menu {
			background-color: #777673;
			position: absolute;
			margin: 0;
			top: 0;
			left: 0;
			right: 0;
			padding: 1rem 0 1rem;
			transition: transform 300ms ease-in;
			transform: translateY(-500px);
			&.open {
				transform: translateY(0);
			}
			ul {
				margin: 0;
			}
			li {
				list-style: none;
				display: block !important;

			}
			a {
				display: block;
				color: white;
				padding: 10px;
				font-weight: bold;
			}
		}
	}
}
@media(min-width: 768px) {
	.mobile-menu,
	.mobile-nav-toggle {
		display: none;
	}
	// #top > .site-banner {
	// 	padding-top: 0;
	// }
	// #top > .site-banner .banner-social {
	// 	top: 25px;
	// 	height: auto;
	// }
	// .page-template-template-companies,
	// .tax-company,
	// .companies {
	// 	#top > .site-banner {
	// 		padding-top: 20px;
	// 	}
	// 	#top > .site-banner .banner-social {
	// 		top: 0;
	// 		padding-top: 30px;
	// 		height: auto;
	// 	}
	// }
}

.guide-logo {
	display: block;
	max-width: 450px;
	font-family: 'Racing Sans One', cursive;
	letter-spacing: 1px;
	color: white;
	@media (min-width: 640px) {
		font-size: 40px;
	}
	&:hover {
		color: white;
	}
}

@media (min-width: 501px ) and (max-width: 767px) {
	.guide-logo {
		max-width: 350px;
	}
	// .page-template-template-companies,
	// .tax-company,
	// .companies {
	// 	#top > .site-banner .banner-social {
	// 		padding-top: 25px;
	// 		height: auto;
	// 	}
	// }
}

@media(max-width: 500px) {
	#top > .site-banner .inner {
		// padding-top: 15px;
		// padding-bottom: 10px;
		// height: auto;
		// display: flex;
		// justify-content: 'space-between';
		
		.guide-logo,
		.logo {
			position: static;
			// font-size: 0;
			flex: 1;
			&:before {
				content: '';
				display: none;
			}
		}
		// .banner-social {
		// 	padding: 0 !important;
		// 	height: 30px;
		// }
	}	
	// .page-template-template-companies,
	// .tax-company,
	// .companies {
	// 	#top > .site-banner {
	// 		padding-top: 0;
	// 	}
	// 	#top > .site-banner .banner-social {
	// 		padding-top: 20px !important;
	// 		height: 45px;
	// 	}
	// }
	// #top > .site-banner .banner-social {
	// 	position: static;
	// 	text-align: center;
	// }
}

.theme-module-header {
	color: white;
	display: flex;
	align-items: center;

	img {
		width: 100%;
		height: auto;
	}
	.module-heading {
		background:#dd2400;
		color:white;
		font-family:Racing Sans One;
		letter-spacing:1px;
		font-size:1.9rem;
		line-height:1;
		@media (min-width: 640px) {
			font-size:2.4rem;
		}
	}
}